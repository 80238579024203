<template>
  <v-card>
    <v-progress-linear v-if="loading || uploading" color="primary" indeterminate></v-progress-linear>
    <v-card-title>
      <v-btn icon class="mr-2" @click="go('AdminToursTemplates')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span v-if="!loading && model.id">{{ model.details.name }}</span>
      <span v-else>New Template</span>
    </v-card-title>
    <v-alert v-if="success" type="success" class="mx-3" dismissible>{{ success }}</v-alert>
    <v-alert v-if="error" type="error" class="mx-3" dismissible>{{ error }}</v-alert>
    <v-card-text v-if="!loading && model" class="pb-0">
      <v-tabs v-model="tab" show-arrows>
        <v-tab>Details</v-tab>
        <v-tab>Location</v-tab>
        <v-tab>Itinerary</v-tab>
        <v-tab>Vehicle Providers</v-tab>
        <v-tab>Actions</v-tab>
        <v-tab>Checklists</v-tab>
        <v-tab>Excursion</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <!-- ---------------------- Details ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 ma-4">
              <div class="overline">Details</div>
              <v-text-field label="Name" v-model="model.details.name"></v-text-field>
              <v-text-field label="Tour Code (4-characters)" v-model="model.code" @keydown="tourCodeChanged" maxlength="5"></v-text-field>
              <v-text-field label="Description" v-model="model.details.description" multi-line></v-text-field>
              <v-text-field label="Spots" v-model="model.details.spots" suffix="riders" type="number"></v-text-field>
              <div class="overline">Duration</div>
              <v-text-field label="Duration" v-model="model.details.duration" @change="durationChanged(model, model.details.duration)" suffix="days" type="number"></v-text-field>
              <v-text-field label="Distance" v-model="model.details.distance" suffix="km" type="number"></v-text-field>
              <div class="overline">Images</div>
              <v-text-field 
                label="Brochure Image URL" 
                v-model="model.images.brochure"
                prepend-icon="mdi-upload"
                @click:prepend="selectFile('brochure')">
              </v-text-field>
              <input type="file" ref="brochure" @change="uploadImage($event, 'brochure')" style="display:none"/>
              <v-text-field 
                label="Banner Image URL" 
                v-model="model.images.banner"
                prepend-icon="mdi-upload"
                @click:prepend="selectFile('banner')">
              </v-text-field>
              <input type="file" ref="banner" @change="uploadImage($event, 'banner')" style="display:none"/>
              <v-text-field 
                label="Badge Image URL" 
                v-model="model.images.badge"
                prepend-icon="mdi-upload"
                @click:prepend="selectFile('badge')">
              </v-text-field>
              <input type="file" ref="badge" @change="uploadImage($event, 'badge')" style="display:none"/>
              <v-text-field 
                label="Email Banner URL" 
                v-model="model.images.email"
                prepend-icon="mdi-upload"
                @click:prepend="selectFile('email')">
              </v-text-field>
              <input type="file" ref="email" @change="uploadImage($event, 'email')" style="display:none"/>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex-1 ma-4">
              <div class="overline">Pricing</div>
              <v-text-field label="Base Price" prefix="$" v-model="model.details.pricing.base" suffix="CAD" type="number"></v-text-field>
              <v-text-field label="Passenger Price" prefix="$" v-model="model.details.pricing.passenger" suffix="CAD" type="number"></v-text-field>
              <v-text-field label="Solo Price" prefix="$" v-model="model.details.pricing.solo" suffix="CAD" type="number"></v-text-field>
              <div class="overline">Difficulty</div>
              <v-text-field label="Description" v-model="model.details.difficulty.description" multi-line></v-text-field>
              <v-radio-group v-model="model.details.difficulty.rating">
                <v-radio value="1" label="Basic"></v-radio>
                <v-radio value="2" label="Intermediate"></v-radio>
                <v-radio value="3" label="Advanced"></v-radio>
              </v-radio-group>
              <v-subheader class="text-caption pl-0">Gravel: {{ model.details.difficulty.gravel }}%</v-subheader>
              <v-slider v-model="model.details.difficulty.gravel" class="px-5 mt-3" :max="100" :min="0" thumb-label></v-slider>
              <div class="overline">Support Van</div>
              <v-checkbox v-model="model.details.supportVan" label="Support Van Available"></v-checkbox>
            </div>
          </div>
        </v-tab-item>
        <!-- ---------------------- Location ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="world-map-container ma-4">
              <v-img class="continent-layer" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fmap%2Fworld.png?alt=media&token=4458f18d-d630-4b7f-90a0-ba63dbe10ae7"></v-img>
              <v-img v-if="model.location.continent == 'North America'" class="continent-layer" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fmap%2Fnorth-america.png?alt=media&token=4c0c85e4-2735-4497-abcb-bb4e8c5974f0"></v-img>
              <v-img v-if="model.location.continent == 'Africa'" class="continent-layer" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fmap%2Fafrica.png?alt=media&token=9e5529bf-3847-446f-8a85-5631afef6c19"></v-img>
              <v-img v-if="model.location.continent == 'Europe'" class="continent-layer" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fmap%2Feurope.png?alt=media&token=f8304b68-072f-434e-b089-6efc1c326261"></v-img>
              <v-img v-if="model.location.continent == 'Antarctica'" class="continent-layer" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fmap%2Fantartica.png?alt=media&token=01a220ce-a258-481e-bcae-697840115212"></v-img>
              <v-img v-if="model.location.continent == 'Asia'" class="continent-layer" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fmap%2Fasia.png?alt=media&token=54672009-612a-41cd-b3a7-b375fa1a8526"></v-img>
              <v-img v-if="model.location.continent == 'Oceania'" class="continent-layer" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fmap%2Foceania.png?alt=media&token=0756d5a2-50cb-4889-bf0e-b152f2efa875"></v-img>
              <v-img v-if="model.location.continent == 'South America'" class="continent-layer" src="https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fmap%2Fsouth-america.png?alt=media&token=2a45a045-cea8-45cd-bf28-15ea882cbda5"></v-img>
            </div>
            <v-divider vertical></v-divider>
            <div class="ma-4 flex-1">
              <v-select label="Continent" v-model="model.location.continent" :items="continents"></v-select>
              <v-select v-if="model.location.continent" v-model="model.location.countries" :items="filtered_countries" item-text="country" label="Countries" block multiple></v-select>
            </div>
          </div>
        </v-tab-item>
        <!-- ---------------------- Itinerary ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 ma-4">
              <div class="overline">Schedule</div>
              <v-expansion-panels v-model="dayIndex" v-if="model.itinerary.length" class="pa-1">
                <v-expansion-panel v-for="(day, index) in model.itinerary" :key="index">
                  <v-expansion-panel-header>
                    <div v-if="day.name" class="overline">Day {{ index + 1 }}: {{ day.name }}</div>
                    <div v-else>Day {{ index + 1 }}</div>
                  </v-expansion-panel-header>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-alert v-else type="warning">
                You must specify a duration for the tour before you can add itinerary items.
              </v-alert>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex-1 ma-4">
              <div v-if="dayIndex != null" class="text-h5 mb-2">Day {{ model.itinerary[dayIndex].day }}</div>
              <v-sheet v-if="dayIndex != null" rounded color="grey lighten-4" class="pa-3">
                <div class="overline">Details</div>
                {{ model.itinerary[dayIndex] }}
                <v-text-field label="Name" v-model="model.itinerary[dayIndex].name"></v-text-field>
                <v-text-field label="Private Portal Description" v-model="model.itinerary[dayIndex].description_portal"></v-text-field>
                <v-text-field label="Private Portal Useful Information" v-model="model.itinerary[dayIndex].information"></v-text-field>
                <v-text-field 
                  label="Image URL" 
                  v-model="model.itinerary[dayIndex].image"
                  prepend-icon="mdi-upload"
                  @click:prepend="selectFile('itinerary', dayIndex)">
                </v-text-field>
                <input type="file" :ref="'itinerary-' + dayIndex" @change="uploadImage($event, 'itinerary', dayIndex)" style="display:none"/>
                <v-text-field label="Paved Distance" v-model="model.itinerary[dayIndex].distance.paved" suffix="km"></v-text-field>
                <v-text-field label="Gravel Distance" v-model="model.itinerary[dayIndex].distance.gravel" suffix="km"></v-text-field>
                <v-checkbox v-model="model.itinerary[dayIndex].optional" label="Optional Riding Day"></v-checkbox>
                <div class="overline">Hotel</div>
                <v-autocomplete
                  v-model="model.itinerary[dayIndex].hotel"
                  :items="hotels"
                  item-text="name"
                  return-object
                  label="Hotel"></v-autocomplete>
                <div class="overline">Restaurants</div>
                <v-autocomplete
                  v-model="model.itinerary[dayIndex].restaurants"
                  :items="restaurants"
                  item-text="name"
                  return-object
                  label="Restaurants"
                  multiple></v-autocomplete>
                <div class="overline">Activities</div>
                <v-autocomplete
                  v-model="model.itinerary[dayIndex].activities"
                  :items="activities"
                  item-text="name"
                  return-object
                  label="Activities"
                  multiple></v-autocomplete>
                <v-text-field label="Public Website Description" v-model="model.itinerary[dayIndex].description"></v-text-field>
              </v-sheet>
            </div>
          </div>
        </v-tab-item>
        <!-- ---------------------- Vehicle Providers ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 ma-4">
              <div class="overline">Assigned Vehicle Providers</div>
              <div class="renedian-list" v-if="model.vehicleProviders && model.vehicleProviders.length">
                <div v-for="(providerId, index) in model.vehicleProviders" :key="'provider-' + index" class="renedian-list-item">
                  <div v-if="fetchProvider(providerId)" class="d-flex flex-row pa-2 align-center">
                    <v-avatar class="mr-3"><v-img :src="fetchProvider(providerId).logo"></v-img></v-avatar>
                    <v-list-item-title>{{ fetchProvider(providerId).name }}</v-list-item-title>
                    <div>
                      <v-btn icon color="red" @click="removeItem('vehicleProviders', index)" class="renedian-list-button">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <v-alert type="error" outlined>No vehicle provider deleted?</v-alert>
                  </div>
                  <v-divider v-if="model.vehicleProviders.length - 1 != index"></v-divider>
                </div>
              </div>
              <v-alert v-else type="info" outlined>No vehicle providers assigned</v-alert>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex-1 ma-4">
              <div class="overline">Available Vehicle Providers</div>
              <div class="renedian-list" v-if="vehicleProviders && vehicleProviders.length">
                <div v-for="(provider, index) in vehicleProviders" :key="'provider-' + index">
                  <template v-if="!isProviderAssigned(provider)">
                    <div class="d-flex flex-row pa-2 align-center">
                      <v-avatar class="mr-3"><v-img :src="provider.logo"></v-img></v-avatar>
                      <v-list-item-title>{{ provider.name }}</v-list-item-title>
                      <div>
                        <v-btn icon color="green" @click="assignItem('vehicleProviders', index)">
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <v-divider v-if="vehicleProviders.length - 1 != index"></v-divider>
                  </template>
                </div>
              </div>
              <v-alert v-else type="info" outlined>No vehicle providers available</v-alert>
            </div>
          </div>
        </v-tab-item>
        <!-- ---------------------- Actions ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 ma-4">
              <div class="overline">Guide Actions</div>
              <div v-if="model.actions.guide.length" class="renedian-list">
                <draggable group="group" ghost-class="ghost" animation="200" :list="model.actions.guide" delay="400" delay-on-touch-only="true">
                  <div v-for="(action, index) in model.actions.guide" :key="'action-guide-'+index">
                    <div class="renedian-list-item">
                      <v-text-field
                        v-if="action.editing"
                        v-model="action.name"
                        single-line
                        label="Checklist Description"
                        hide-details
                        autofocus
                        dense
                        append-icon="mdi-keyboard-return"
                        @click:append="action.editing = false"
                        @keydown.enter="action.editing = false"
                        @blur="action.editing = false"
                        outlined
                        filled
                        placeholder="Task Description"
                        maxlength="48"
                      ></v-text-field>
                      <div v-else class="pa-2 d-flex align-center">
                        <v-icon class="mr-1">mdi-drag-vertical</v-icon>
                        <div v-if="action.name" class="flex-1 my-1 overline">
                          {{ action.name }}
                        </div>
                        <div v-else class="flex-1 my-1 font-italic">
                          Untitled
                        </div>
                        <v-btn class="renedian-list-button" small icon @click="removeListItem('actions', 'guide', index)"><v-icon small>mdi-close</v-icon></v-btn>
                        <v-btn class="renedian-list-button" small icon @click="action.editing = true"><v-icon small>mdi-pencil</v-icon></v-btn>
                      </div>
                    </div>
                    <v-divider v-if="index != model.actions.guide.length - 1"></v-divider>
                  </div>
                </draggable>
              </div>
              <v-alert type="info" outlined v-else>
                No guide actions
              </v-alert>
              <v-btn block outlined @click="addListItem('actions', 'guide')" class="mt-4"><v-icon left>mdi-plus</v-icon>Add Action Item</v-btn>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex-1 ma-4">
              <div class="overline">Customer Actions</div>
              <div v-if="model.actions.customer.length" class="renedian-list">
                <draggable group="group" ghost-class="ghost" animation="200" :list="model.actions.customer" delay="400" delay-on-touch-only="true">
                  <div v-for="(action, index) in model.actions.customer" :key="'action-customer-'+index">
                    <div class="renedian-list-item">
                      <v-text-field
                        v-if="action.editing"
                        v-model="action.name"
                        single-line
                        label="Checklist Description"
                        hide-details
                        autofocus
                        dense
                        append-icon="mdi-keyboard-return"
                        @click:append="action.editing = false"
                        @keydown.enter="action.editing = false"
                        @blur="action.editing = false"
                        outlined
                        filled
                        placeholder="Task Description"
                        maxlength="48"
                      ></v-text-field>
                      <div v-else class="pa-2 d-flex align-center">
                        <v-icon class="mr-1">mdi-drag-vertical</v-icon>
                        <div v-if="action.name" class="flex-1 my-1 overline">
                          {{ action.name }}
                        </div>
                        <div v-else class="flex-1 my-1 font-italic">
                          Untitled
                        </div>
                        <v-btn class="renedian-list-button" small icon @click="removeListItem('actions', 'customer', index)"><v-icon small>mdi-close</v-icon></v-btn>
                        <v-btn class="renedian-list-button" small icon @click="action.editing = true"><v-icon small>mdi-pencil</v-icon></v-btn>
                      </div>
                    </div>
                    <v-divider v-if="index != model.actions.customer.length - 1"></v-divider>
                  </div>
                </draggable>
              </div>
              <v-alert type="info" outlined v-else>
                No customer actions
              </v-alert>
              <v-btn block outlined @click="addListItem('actions', 'customer')" class="mt-4"><v-icon left>mdi-plus</v-icon>Add Action Item</v-btn>
            </div>
          </div>
        </v-tab-item>
        <!-- ---------------------- Checklists ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 ma-4">
              <div class="overline">Guide Checklist</div>
              <div v-if="model.checklists.guide.length" class="renedian-list">
                <draggable group="group" ghost-class="ghost" animation="200" :list="model.checklists.guide" delay="400" delay-on-touch-only="true">
                  <div v-for="(action, index) in model.checklists.guide" :key="'checklist-guide-'+index">
                    <div class="renedian-list-item">
                      <v-text-field
                        v-if="action.editing"
                        v-model="action.name"
                        single-line
                        label="Checklist Description"
                        hide-details
                        autofocus
                        dense
                        append-icon="mdi-keyboard-return"
                        @click:append="action.editing = false"
                        @keydown.enter="action.editing = false"
                        @blur="action.editing = false"
                        outlined
                        filled
                        placeholder="Task Description"
                        maxlength="48"
                      ></v-text-field>
                      <div v-else class="pa-2 d-flex align-center">
                        <v-icon class="mr-1">mdi-drag-vertical</v-icon>
                        <div v-if="action.name" class="flex-1 my-1 overline">
                          {{ action.name }}
                        </div>
                        <div v-else class="flex-1 my-1 font-italic">
                          Untitled
                        </div>
                        <v-btn class="renedian-list-button" small icon @click="removeListItem('checklists', 'guide', index)"><v-icon small>mdi-close</v-icon></v-btn>
                        <v-btn class="renedian-list-button" small icon @click="action.editing = true"><v-icon small>mdi-pencil</v-icon></v-btn>
                      </div>
                    </div>
                    <v-divider v-if="index != model.checklists.guide.length - 1"></v-divider>
                  </div>
                </draggable>
              </div>
              <v-alert type="info" outlined v-else>
                No guide checklist items
              </v-alert>
              <v-btn block outlined @click="addListItem('checklists', 'guide')" class="mt-4"><v-icon left>mdi-plus</v-icon>Add Checklist Item</v-btn>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex-1 ma-4">
              <div class="overline">Customer Checklist</div>
              <div v-if="model.checklists.customer.length" class="renedian-list">
                <draggable group="group" ghost-class="ghost" animation="200" :list="model.checklists.customer" delay="400" delay-on-touch-only="true">
                  <div v-for="(action, index) in model.checklists.customer" :key="'checklist-customer-'+index">
                    <div class="renedian-list-item">
                      <v-text-field
                        v-if="action.editing"
                        v-model="action.name"
                        single-line
                        label="Checklist Description"
                        hide-details
                        autofocus
                        dense
                        append-icon="mdi-keyboard-return"
                        @click:append="action.editing = false"
                        @keydown.enter="action.editing = false"
                        @blur="action.editing = false"
                        outlined
                        filled
                        placeholder="Task Description"
                        maxlength="48"
                      ></v-text-field>
                      <div v-else class="pa-2 d-flex align-center">
                        <v-icon class="mr-1">mdi-drag-vertical</v-icon>
                        <div v-if="action.name" class="flex-1 my-1 overline">
                          {{ action.name }}
                        </div>
                        <div v-else class="flex-1 my-1 font-italic">
                          Untitled
                        </div>
                        <v-btn class="renedian-list-button" small icon @click="removeListItem('checklists', 'customer', index)"><v-icon small>mdi-close</v-icon></v-btn>
                        <v-btn class="renedian-list-button" small icon @click="action.editing = true"><v-icon small>mdi-pencil</v-icon></v-btn>
                      </div>
                    </div>
                    <v-divider v-if="index != model.checklists.customer.length - 1"></v-divider>
                  </div>
                </draggable>
              </div>
              <v-alert type="info" outlined v-else>
                No customer checklists
              </v-alert>
              <v-btn block outlined @click="addListItem('checklists', 'customer')" class="mt-4"><v-icon left>mdi-plus</v-icon>Add Checklist Item</v-btn>
            </div>
          </div>
        </v-tab-item>
        <!-- ---------------------- Excursion ---------------------- -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <div class="flex-1 ma-4">
              <!-- ------------ Availability ------------ -->
              <div class="overline">Excursion Availability</div>
              <v-switch v-model="model.excursion.available"
                class="px-4"
                label="Excursion Available"
              ></v-switch>
              <template v-if="model.excursion.available">
                <!-- ------------ Duration ------------ -->
                <div class="overline">Duration</div>
                <v-text-field label="Duration" v-model="model.excursion.duration" @change="durationChanged(model.excursion, model.excursion.duration)" suffix="days" type="number"></v-text-field>
                <!-- ------------ Description ------------ -->
                <div class="overline">Description</div>
                <v-text-field label="Name" v-model="model.excursion.description.name"></v-text-field>
                <v-text-field label="Short Description" v-model="model.excursion.description.short"></v-text-field>
                <v-text-field label="Long Description" v-model="model.excursion.description.long" multi-line></v-text-field>
                <!-- ------------ Images ------------ -->
                <div class="overline">Images</div>
                <v-text-field 
                  label="Badge URL" 
                  v-model="model.excursion.images.badge"
                  prepend-icon="mdi-upload"
                  @click:prepend="selectFile('excursionBadge')">
                </v-text-field>
                <input type="file" ref="excursionBadge" @change="uploadImage($event, 'excursionBadge')" style="display:none"/>
                <v-text-field 
                  label="Thumbnail URL" 
                  v-model="model.excursion.images.thumbnail"
                  prepend-icon="mdi-upload"
                  @click:prepend="selectFile('excursionThumbnail')">
                </v-text-field>
                <input type="file" ref="excursionThumbnail" @change="uploadImage($event, 'excursionThumbnail')" style="display:none"/>
                <v-text-field 
                  label="Photo URL" 
                  v-model="model.excursion.images.photo"
                  prepend-icon="mdi-upload"
                  @click:prepend="selectFile('excursionPhoto')">
                </v-text-field>
                <input type="file" ref="excursionPhoto" @change="uploadImage($event, 'excursionPhoto')" style="display:none"/>
                <!-- ------------ Pricing ------------ -->
                <div class="overline">Pricing</div>
                <v-text-field label="Base Price" prefix="$" v-model="model.excursion.pricing.base" suffix="CAD" type="number"></v-text-field>
                <v-text-field label="Solo Price" prefix="$" v-model="model.excursion.pricing.solo" suffix="CAD" type="number"></v-text-field>
                <!-- ------------ Schedule ------------ -->
                <div class="overline">Excursion Schedule</div>
                <v-expansion-panels v-model="excursionDayIndex" v-if="model.excursion.duration">
                  <v-expansion-panel v-for="(day, index) in model.excursion.itinerary" :key="index">
                    <v-expansion-panel-header>
                      <div v-if="day.name" class="overline">Day {{ index + 1 }}: {{ day.name }}</div>
                      <div v-else>Day {{ index + 1 }}</div>
                    </v-expansion-panel-header>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
            <v-divider vertical></v-divider>
            <div v-if="model.excursion.available" class="flex-1 ma-4">
              <div v-if="excursionDayIndex != null" class="text-h5 mb-2">Day {{ model.excursion.itinerary[excursionDayIndex].day }}</div>
              <v-sheet v-if="excursionDayIndex != null" rounded color="grey lighten-4" class="pa-3">
                <div class="overline">Details</div>
                <v-text-field label="Name" v-model="model.excursion.itinerary[excursionDayIndex].name"></v-text-field>
                <v-text-field label="Description" v-model="model.excursion.itinerary[excursionDayIndex].description"></v-text-field>
                <v-text-field 
                  label="Image URL" 
                  v-model="model.excursion.itinerary[excursionDayIndex].image"
                  prepend-icon="mdi-upload"
                  @click:prepend="selectFile('excursionItinerary', excursionDayIndex)">
                </v-text-field>
                <input type="file" :ref="'excursionItinerary-' + excursionDayIndex" @change="uploadImage($event, 'excursionItinerary', excursionDayIndex)" style="display:none"/>
                <v-text-field label="Paved Distance" v-model="model.excursion.itinerary[excursionDayIndex].distance.paved" suffix="km"></v-text-field>
                <v-text-field label="Gravel Distance" v-model="model.excursion.itinerary[excursionDayIndex].distance.gravel" suffix="km"></v-text-field>
                <v-checkbox v-model="model.excursion.itinerary[excursionDayIndex].optional" label="Optional Riding Day"></v-checkbox>
                <div class="overline">Hotel</div>
                <v-autocomplete
                  v-model="model.excursion.itinerary[excursionDayIndex].hotel"
                  :items="hotels"
                  item-text="name"
                  return-object
                  label="Hotel">
                </v-autocomplete>
                <div class="overline">Restaurants</div>
                <v-autocomplete
                  v-model="model.excursion.itinerary[excursionDayIndex].restaurants"
                  :items="restaurants"
                  item-text="name"
                  return-object
                  label="Restaurants"
                  multiple>
                </v-autocomplete>
                <div class="overline">Activities</div>
                <v-autocomplete
                  v-model="model.excursion.itinerary[excursionDayIndex].activities"
                  :items="activities"
                  item-text="name"
                  return-object
                  label="Activities"
                  multiple>
                </v-autocomplete>
              </v-sheet>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions class="mt-0">
      <v-spacer></v-spacer>
      <v-btn v-if="model.id" color="primary" @click="update()" :loading="saving">
        <v-icon left>mdi-content-save-outline</v-icon>Save Changes
      </v-btn>
      <v-btn v-else color="primary" @click="create()" :loading="saving">
        <v-icon left>mdi-content-save-outline</v-icon>Save Template
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getFirestore, doc, getDocs, addDoc, setDoc, onSnapshot, collection } from 'firebase/firestore'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Draggable from "vuedraggable"
import { isEqual, cloneDeep } from 'lodash-es'

export default {
  name: 'AdminToursTemplate',
  components: {
    Draggable
  },
  data: () => ({
    // ------------ Common ------------
    loading: true,
    uploading: false,
    saving: false,
    success: '',
    error: '',
    tab: 0,
    dayIndex: null,
    excursionDayIndex: null,
    // ------------ Model Data ------------
    model: [],
    defaultModel: {
      id: null,
      code: "AA",
      order: 0,
      // Details
      details: {
        name: "",
        description: "",
        duration: 0,
        distance: 0,
        pricing: {
          base: 0, // Shared room
          solo: 0, // Solo room
          passenger: 0, // passenger price
        },
        spots: 10,
        difficulty: {
          "description": "",
          "gravel": 0,
          "rating": 1
        },
        supportVan: true,
      },
      // Images
      images: {
        brochure: "",
        banner: "",
        badge: "",
        email: "",
      },
      // Location
      location: {
        continent: "",
        countries: [],
      },
      // Itinerary
      itinerary: [],
      // Crew
      crew: [],
      // Vehicle Providers
      vehicleProviders: [],
      // Actions
      actions: {
        guide: [],
        customer: []
      },
      // Checklists
      checklists: {
        guide: [],
        customer: []
      },
      // Excursion
      excursion: {
        available: false,
        duration: 0,
        description: {
          name: "",
          short: "",
          long: "",
        },
        images: {
          badge: "",
          thumbnail: "",
          photo: "",
        },
        pricing: {
          base: 0, // Shared room
          solo: 0, // Solo room
        },
        itinerary: [],
      }
    },
    // ------------ Data ------------
    employees: [],
    vehicles: [],
    vehicleProviders: [],
    hotels: [],
    restaurants: [],
    activities: [],
    continents: [
      'Africa',
      'Antarctica',
      'Asia',
      'Oceania',
      'Europe',
      'North America',
      'South America',
    ],
    countries: [],
    loadedTables: [],
  }),
  created() {
    // ------------ Load Template ------------
    this.model.id = this.$route.params.id
    if (this.model.id == 'new') {
      this.model = JSON.parse(JSON.stringify(this.defaultModel)) // Load default
      this.loaded('template')
    } else {
      const db = getFirestore()
      const docRef = doc(db, 'templates', this.model.id)
      onSnapshot(docRef, (doc) => {
        let template = doc.data()
        template.id = doc.id
        // Temporary: Check for missing fields
        if (template.excursion.description == undefined) {
          template.excursion = this.defaultModel.excursion
          alert('excursion updated')
        }
        if (template.details.difficulty.rating == undefined) {
          template.details.difficulty = this.defaultModel.details.difficulty
          alert('difficulty updated')
        }
        // End Temporary
        template.vehicleProviders = template.vehicleProviders || []; // Ensure vehicleProviders is always defined
        this.model = template
        this.loaded('template')
      })
    }
    // ------------ Load Data ------------
    this.load('hotels') // Load hotels
    this.load('restaurants') // Load restaurants
    this.load('activities') // Load activities
    this.load('vehicleProviders') // Load vehicle providers
    // ------------ Load Continents ------------
    this.countries = require('@/assets/continents.json')
  },
  methods: {
    create() {
      this.saving = true
      this.error = ''
      this.success = ''
      const db = getFirestore()
      const collectionRef = collection(db, 'templates')
      addDoc(collectionRef, this.model).then((docRef) => {
        this.success = "Template added successfully."
        this.saving = false
        this.model.id = docRef.id
      }).catch((error) => {
        this.error = "Error adding template. Please try again."
        this.saving = false
      })
    },
    update() {
      this.saving = true
      this.error = ''
      this.success = ''
      const db = getFirestore()
      const collectionRef = collection(db, 'templates')
      const docRef = doc(collectionRef, this.model.id)
      setDoc(docRef, this.model).then(() => {
        this.success = "Updated template successfully."
        this.saving = false
      }).catch((error) => {
        this.error = "Error updating template. Please try again."
        this.saving = false
      })
    },
    load(table) {
      const db = getFirestore()
      const collectionRef = collection(db, table)
      getDocs(collectionRef).then((querySnapshot) => {
        let items = []
        querySnapshot.forEach((doc) => {
          let item = doc.data()
          item.id = doc.id
          items.push(item)
        })
        this.$set(this, table, items)
        this.loaded(table)
      })
    },
    loaded(table) {
      // ------------ Special Triggers ------------
      // if () {

      // }
      // ------------ Loading Complete ------------
      if (this.employees && this.vehicles && this.hotels && this.restaurants && this.activities) {
        this.loading = false
      }
    },
    // durationChanged() {
    //   // The user has changed the duration of the tour. So we have to manage the itinerary array.
    //   let itinerary = []
    //   for( var i=0; i<this.model.details.duration; i++ ) {
    //     itinerary.push({
    //       day: i+1,
    //       name: '',
    //       subtitle: '',
    //       description: '',
    //       image: '',
    //       distance: 0,
    //       hotel: [],
    //       restaurants: [],
    //       activities: [],
    //     })
    //   }       
    //   this.model.itinerary = itinerary
    // },
    durationChanged(model, duration) {
      let emptyItinerary = []
      for (var i = 0; i < duration; i++) {
        emptyItinerary.push({
          day: i + 1,
          name: '',
          subtitle: '',
          description: '',
          image: '',
          optional: false,
          distance: {
            paved: 0,
            gravel: 0
          },
          hotel: [],
          restaurants: [],
          activities: [],
        })
      }
      model.itinerary = emptyItinerary
    },
    assignItem(list, index) {
      if (list == 'vehicleProviders') {
        this.model.vehicleProviders.push(this.vehicleProviders[index].id)
        console.log(this.vehicleProviders)
      }
      this.$forceUpdate()
    },
    removeItem(list, index) {
      if (list == 'vehicleProviders') {
        this.model.vehicleProviders.splice(index, 1)
      }
      this.$forceUpdate()
    },
    fetchProvider(id) {
      let provider = this.vehicleProviders.find(provider => provider.id == id)
      if (!provider) {
        console.log('x', id)
        // remove item from model
        this.model.vehicleProviders = this.model.vehicleProviders.filter(providerId => providerId != id)
        return null
      }
      return provider
    },
    addListItem(list, type) {
      this.model[list][type].push({
        name: '',
        editing: true,
      })
    },
    removeListItem(list, type, index) {
      this.model[list][type].splice(index, 1)
    },
    selectFile(type, index = null) {
      if (type === 'itinerary' || type === 'excursionItinerary') {
        this.$refs[`${type}-${index}`].click();
      } else {
        this.$refs[type].click();
      }
    },
    uploadImage(event, type, index = null) {
      const file = event.target.files[0];
      const storage = getStorage();
      let storageRef;

      if (type === 'itinerary' || type === 'excursionItinerary') {
        storageRef = ref(storage, `images/${type}/${index}`);
      } else {
        storageRef = ref(storage, `images/${type}`);
      }

      const uploadTask = uploadBytesResumable(storageRef, file);

      this.uploading = true;

      uploadTask.on('state_changed', 
        (snapshot) => {},
        (error) => {
          this.error = "Error uploading image. Please try again.";
          this.uploading = false;
        }, 
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            if (type === 'itinerary') {
              this.model.itinerary[index].image = url;
            } else if (type === 'excursionItinerary') {
              this.model.excursion.itinerary[index].image = url;
            } else if (type === 'excursionBadge') {
              this.model.excursion.images.badge = url;
            } else if (type === 'excursionThumbnail') {
              this.model.excursion.images.thumbnail = url;
            } else if (type === 'excursionPhoto') {
              this.model.excursion.images.photo = url;
            } else {
              this.model.images[type] = url;
            }
            this.uploading = false;
          });
        }
      );
    },
    isProviderAssigned(provider) {
      return this.model.vehicleProviders.includes(provider.id)
    },
    tourCodeChanged(event) {
      // console.log(event)
      // Get the key that was pressed
      let key = event.key
      // Get the current code but remove the hyphen
      let code = this.model.code.replace(/-/g, '')
      // Prevent the default event
      event.preventDefault()
      // Handle backspace
      if (key === 'Backspace') {
        // Remove the last character from the code
        if (code.length > 0) {
          this.model.code = this.model.code.slice(0, -1)
          // If we have a hyphen at the end, remove it
          if (this.model.code[this.model.code.length - 1] === '-') this.model.code = this.model.code.slice(0, -1)
        }
        return
      }
      // Maximum of 4 characters
      if (code.length >= 4) return
      // The key should only be one character
      if (key.length > 1) return
      // Only allow alphabetic characters
      if (!key.match(/[a-zA-Z]/i)) return
      // Capitalize the key
      key = key.toUpperCase()
      // Add the key to the code
      code += key
      // Insert a hyphen after the first two characters, but only if we have more than 2 characters 
      if (code.length > 2) code = code.slice(0, 2) + '-' + code.slice(2)
      // Update the model
      this.model.code = code
    }
  },
  computed: {
    filtered_countries() {
      return this.countries.filter(country => country.continent == this.model.location.continent)
    },
  },
}
</script>

<style scoped lang="scss">
  .world-map-container {
    position: relative;
    width: 400px;
    height: 300px;
  }
  .continent-layer {
    width: 400px;
    height: 300px;
    position: absolute;
  }
  .renedian-list {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    .renedian-list-item {
      background: white;
      .renedian-list-button {
        display: none;
      }
    }
    .renedian-list-item:hover {
      .renedian-list-button {
        display: flex;
      }
    }
  }
  .ghost {
    background: #ef6c00 !important;
  }
  .skill-highlight {
    border: 2px solid #ef6c00;
    box-sizing: border-box;
  }
</style>