<template>
  <div>
    <canvas
      ref="signatureCanvas"
      class="signature-canvas"
      @mousedown="startDrawing"
      @mousemove="draw"
      @mouseup="stopDrawing"
      @mouseleave="stopDrawing"
      @touchstart="startDrawing"
      @touchmove="draw"
      @touchend="stopDrawing"
    ></canvas>
    <div class="controls">
      <v-btn color="primary" @click="clearCanvas">Clear</v-btn>
      <v-btn color="primary" @click="saveSignature">Save Signature</v-btn>
    </div>
    <div v-if="signatureData">
      <h4>Saved Signature:</h4>
      <img :src="signatureData" alt="Signature" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignaturePad',
  data() {
    return {
      isDrawing: false,
      points: [], // To store points for smoother lines
      signatureData: null, // Holds the saved signature as a Base64 image
    };
  },
  methods: {
    getCoordinates(event) {
      const canvas = this.$refs.signatureCanvas;
      const rect = canvas.getBoundingClientRect();
      if (event.touches) {
        const touch = event.touches[0];
        return {
          x: touch.clientX - rect.left,
          y: touch.clientY - rect.top,
        };
      } else {
        return {
          x: event.clientX - rect.left,
          y: event.clientY - rect.top,
        };
      }
    },
    startDrawing(event) {
      this.isDrawing = true;
      const { x, y } = this.getCoordinates(event);
      this.points.push({ x, y, time: Date.now() }); // Save the starting point
    },
    draw(event) {
      if (!this.isDrawing) return;

      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext("2d");
      const { x, y } = this.getCoordinates(event);
      const time = Date.now();

      // Add the new point
      this.points.push({ x, y, time });

      // Draw using quadratic Bézier curve
      if (this.points.length >= 3) {
        const last = this.points[this.points.length - 1];
        const mid = this.points[this.points.length - 2];
        const prev = this.points[this.points.length - 3];

        // Calculate line width based on speed
        const speed = Math.sqrt(
          Math.pow(last.x - mid.x, 2) + Math.pow(last.y - mid.y, 2)
        ) / (last.time - mid.time || 1);
        const lineWidth = Math.max(2, Math.min(10, 10 / (speed + 1)));

        ctx.beginPath();
        ctx.moveTo(prev.x, prev.y);
        ctx.quadraticCurveTo(mid.x, mid.y, last.x, last.y);
        ctx.lineWidth = lineWidth;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.strokeStyle = "#000"; // Black ink
        ctx.stroke();
      }

      // Prevent scrolling on mobile
      if (event.touches) {
        event.preventDefault();
      }
    },
    stopDrawing() {
      this.isDrawing = false;
      this.points = []; // Clear the points after completing the stroke
    },
    clearCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.signatureData = null;
    },
    saveSignature() {
      const canvas = this.$refs.signatureCanvas;
      this.signatureData = canvas.toDataURL("image/png");
      console.log("Signature saved:", this.signatureData);
    },
  },
  mounted() {
    const canvas = this.$refs.signatureCanvas;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    // Initialize canvas drawing context
    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
  },
};
</script>
<style scoped>
.signature-canvas {
  border: 1px solid #000;
  width: 100%;
  height: 150px;
  touch-action: none; /* Disable touch scrolling for drawing */
}
.controls {
  margin-top: 10px;
}
</style>