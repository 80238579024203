<template>
  <v-container>
    <div class="g-font renedian-red--text mb-6 font-size-22">Profile</div>
    <v-tabs v-model="tab">
      <v-tab>Personal</v-tab>
      <v-tab>Alumni</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <!-- Personal -->
      <v-tab-item class="pa-2">
        <v-card>
          <v-card-title>Personal Details</v-card-title>
          <v-card-text>
            <!-- First Name -->
            <h5>First Name</h5>
            <div v-if="editing == 'first'" class="d-flex flex-row">
              <v-text-field v-model="profile.name.first" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'first'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.name.first }}</div>
              <v-btn icon><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
            <!-- Last Name -->
            <h5 class="mt-4">Last Name</h5>
            <div v-if="editing == 'last'" class="d-flex flex-row">
              <v-text-field v-model="profile.name.last" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'last'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.name.last }}</div>
              <v-btn icon><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
            <!-- Email -->
            <h5 class="mt-4">Email</h5>
            <div v-if="editing == 'email'" class="d-flex flex-row">
              <v-text-field v-model="profile.contact.email" :rules="[rules.email]" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'email'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.contact.email }}</div>
              <v-btn icon><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
            <!-- Phone -->
            <h5 class="mt-4">Phone Number</h5>
            <div v-if="editing == 'phone'" class="d-flex flex-row">
              <!-- <v-text-field v-model="profile.contact.phone" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field> -->
              <v-phone-input
                v-model="profile.contact.phone"
                label="Phone Number"
                filled
                single-line
                :defaultCountry="profile.address.country"
                class="d-flex flex-row flex-1"
              ></v-phone-input>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'phone'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.contact.phone }}</div>
              <v-btn icon class="mt-3"><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-6">
          <v-card-title>Address</v-card-title>
          <v-card-text>
            <!-- Street -->
            <h5 class="mt-4">Street Address</h5>
            <div v-if="editing == 'street'" class="d-flex flex-row">
              <v-text-field v-model="profile.address.street" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'street'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.address.street }}</div>
              <v-btn icon><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
            <!-- City -->
            <h5 class="mt-4">City</h5>
            <div v-if="editing == 'city'" class="d-flex flex-row">
              <v-text-field v-model="profile.address.city" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'city'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.address.city }}</div>
              <v-btn icon><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
            <!-- Province -->
            <h5 class="mt-4">Province</h5>
            <div v-if="editing == 'province'" class="d-flex flex-row">
              <v-text-field v-model="profile.address.province" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'province'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.address.province }}</div>
              <v-btn icon><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
            <!-- Postal Code -->
            <h5 class="mt-4">Postal Code</h5>
            <div v-if="editing == 'postal_code'" class="d-flex flex-row">
              <v-text-field v-model="profile.address.postal_code" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'postal_code'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.address.postal_code }}</div>
              <v-btn icon><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
            <!-- Country -->
            <h5 class="mt-4">Country</h5>
            <div v-if="editing == 'country'" class="d-flex flex-row">
              <v-text-field v-model="profile.address.country" autofocus append-icon="mdi-keyboard-return" @keydown.enter="saveProfile()" @click:append="saveProfile()" filled dense hide-details></v-text-field>
              <v-btn @click="saveProfile()" class="mt-1" icon><v-icon small>mdi-check</v-icon></v-btn>
            </div>
            <div v-else @click="editing = 'country'" class="d-flex flex-row">
              <div class="profile-field d-flex flex-1">{{ profile.address.country }}</div>
              <v-btn icon><v-icon small>mdi-pencil</v-icon></v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- Alumni -->
      <v-tab-item>
        <h3 class="pa-6 pb-0">Your Tour Badges</h3>
        <div v-if="alumni.length" class="d-flex flex-row pa-6">
          <div v-for="(item, index) in alumni" :key="'badge-' + index" class="alumni-badges">
            <img :src="item.badge" class="alumni-badge pa-4"></img>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <!-- <v-snackbar v-model="snackbar" color="success" timeout="2000">
      Profile saved successfully
    </v-snackbar> -->
  </v-container>
</template>
<script>
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'
export default {
  name: 'PortalProfile',
  props: ['user', 'bookings'],
  data: () => ({
    tab: 0,
    alumni: [],
    editing: '',
    snackbar: false,
    profile: {
      name: {
        first: '',
        last: ''
      },
      contact: {
        email: '',
        phone: ''
      },
      address: {
        street: '',
        city: '',
        province: '',
        postal_code: '',
        country: ''
      },
    },
    rules: {
      required: value => !!value || 'Required.',
      email: value => {
        const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return pattern.test(value) || 'Invalid email address.';
      },
      match: (value, other) => value === other || 'E-mails do not match.',
    },
  }),
  methods: {
    initialize() {
      console.log('initialize')
      // Initialize Profile
      this.profile = this.user.profile
      // For each Booking, get the Template
      const db = getFirestore()
      this.bookings.forEach(async (booking) => {
        const docRef = doc(db, 'tours', booking.tour.id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const data = docSnap.data()
          // if (data.status == 'complete') {
            this.alumni.push({
              name: data.template.name,
              badge: data.template.images.badge
            })
          // }
        }
      })
    },
    saveProfile() {
      this.editing = ''
      // Merge profile with user, some fields may be missing
      this.user.profile = { ...this.user.profile, ...this.profile }
      // Save to Firestore
      const db = getFirestore()
      const docRef = doc(db, 'users', this.user.uid)
      setDoc(docRef, this.user).then(() => {
        this.snackbar = true
      }).catch((error) => {
        console.error('Error adding document: ', error)
      })
    }
  },
  created() {
    this.initialize()
  },
}
</script>
<style lang="scss" scoped>
  .profile-field {
    border-bottom: 1px dashed #ccc;
    align-items: center;
  }
  .alumni-badge {
    width: 150px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  }
</style>