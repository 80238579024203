import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth } from 'firebase/auth'
import { getFirestore, doc, getDoc, getDocs, query, where, collection, limit } from 'firebase/firestore'
// ------------ Public Views ------------
import Booking from '../views/public/Booking.vue'
import Choose from '../views/public/Choose.vue'
import Gear from '../views/public/Gear.vue'
import Landing from '../views/public/Landing.vue'
import Lost from '../views/public/Lost.vue'
import Privacy from '../views/public/Privacy.vue'
import Rentals from '../views/public/Rentals.vue'
import Tales from '../views/public/Tales.vue'
// import Tour from '../views/public/Tour.vue'
import Tours from '../views/public/Tours.vue'
import Test from '../views/public/Test.vue'
// ------------ Public Tour Pages ------------
import Africa from '../views/public/tours/Africa.vue'
import Canada from '../views/public/tours/Canada.vue'
import CapeTownToVictoriaFalls from '../views/public/tours/CapeTownToVictoriaFalls.vue'
import GobiDiscovery from '../views/public/tours/GobiDiscovery.vue'
import GoldenEagleFestival from '../views/public/tours/GoldenEagleFestival.vue'
import JourneyToUshuaia from '../views/public/tours/JourneyToUshuaia.vue'
import Mongolia from '../views/public/tours/Mongolia.vue'
import NorthToTheYukon from '../views/public/tours/NorthToTheYukon.vue'
import Scotland from '../views/public/tours/Scotland.vue'
import SouthAmerica from '../views/public/tours/SouthAmerica.vue'
import SpectacularSouthWestAfrica from '../views/public/tours/SpectacularSouthWestAfrica.vue'
import TheBestOfTheCanadianRockies9 from '../views/public/tours/TheBestOfTheCanadianRockies9.vue'
import TheBestOfTheCanadianRockies12 from '../views/public/tours/TheBestOfTheCanadianRockies12.vue'
import TheGrandTour from '../views/public/tours/TheGrandTour.vue'
import TwoOceans from '../views/public/tours/TwoOceans.vue'
import VictoriaFallsToCapeTown from '../views/public/tours/VictoriaFallsToCapeTown.vue'
import WaterfallsAndWildlife from '../views/public/tours/WaterfallsAndWildlife.vue'
// ------------ Rider Portal Views ------------
import PortalBilling from '../views/portal/Billing.vue'
import PortalDashboard from '../views/portal/Dashboard.vue'
import PortalLogin from '../views/portal/Login.vue'
import PortalProfile from '../views/portal/Profile.vue'
import PortalTour from '../views/portal/Tour.vue'
// ------------ Admin Views ------------
// Dashboard
import AdminDashboard from '../views/admin/Dashboard.vue'
import AdminPending from '../views/admin/Pending.vue'
import AdminLogin from '../views/admin/Login.vue'
// Tours
import AdminTours from '../views/admin/Tours.vue'
import AdminToursManage from '../views/admin/ToursManage.vue'
import AdminToursTemplate from '../views/admin/ToursTemplate.vue'
import AdminToursTemplates from '../views/admin/ToursTemplates.vue'
// Bookings
import AdminBookings from '../views/admin/Bookings.vue'
// People
import AdminCustomers from '../views/admin/PeopleCustomers.vue'
import AdminEmployees from '../views/admin/PeopleEmployees.vue'
import AdminLeads from '../views/admin/PeopleLeads.vue'
// Automation
import AdminAutomationEmail from '../views/admin/AutomationEmail.vue'
import AdminAutomationAI from '../views/admin/AutomationAI.vue'
// Insights
import AdminInsightsCosting from '../views/admin/InsightsCosting.vue'
import AdminInsightsMetrics from '../views/admin/InsightsMetrics.vue'
import AdminInsightsOverview from '../views/admin/InsightsOverview.vue'
// Guides
import AdminGuideCurrentTour from '../views/admin/GuideCurrentTour.vue'
import AdminGuideProfile from '../views/admin/GuideProfile.vue'
import AdminGuideUpcomingTours from '../views/admin/GuideUpcomingTours.vue'
// Vendors
import AdminVendorsActivities from '../views/admin/VendorsActivities.vue'
import AdminVendorsActivity from '../views/admin/VendorsActivity.vue'
import AdminVendorsHotel from '../views/admin/VendorsHotel.vue'
import AdminVendorsHotels from '../views/admin/VendorsHotels.vue'
import AdminVendorsRestaurant from '../views/admin/VendorsRestaurant.vue'
import AdminVendorsRestaurants from '../views/admin/VendorsRestaurants.vue'
import AdminVendorsVehicles from '../views/admin/VendorsVehicles.vue'
// Temp
import Invoice from '../components/Invoice.vue'
 
Vue.use(VueRouter)

const routes = [
  {
    path: '/invoice',
    name: 'Invoice',
    component: Invoice,
    meta: {
      scope: 'public',
      title: 'Invoice'
    }
  },
  // ---------------------- Public Routes ----------------------
  {
    path: '/booking',
    name: 'Booking',
    component: Booking,
    meta: {
      scope: 'public',
      title: 'Tour Booking'
    }
  },
  {
    path: '/booking/:id',
    name: 'BookingWithId',
    component: Booking,
    meta: {
      scope: 'public',
      title: 'Tour Booking'
    }
  },
  {
    path: '/why-choose-renedian',
    name: 'Choose',
    component: Choose,
    meta: {
      scope: 'public',
      title: 'Why choose Renedian?'
    }
  },
  {
    path: '/gear',
    name: 'Gear',
    component: Gear,
    meta: {
      scope: 'public',
      title: 'Gear'
    }
  },
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      scope: 'public',
      // template: 'Experimental',
      template: 'Mockup',
    }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      scope: 'public',
      title: 'Privacy Policy'
    }
  },
  {
    path: '/motorcycle-rentals',
    name: 'Rentals',
    component: Rentals,
    meta: {
      scope: 'public',
      title: 'Motorcycle Rentals'
    }
  },
  {
    path: '/rider-tales',
    name: 'Tales',
    component: Tales,
    meta: {
      scope: 'public',
      title: 'Rider Tales'
    }
  },
  // ---------------------- Public Routes - Tours ----------------------
  {
    path: '/adventure-motorcycle-tours/',
    name: 'Tours',
    component: Tours,
    meta: {
      scope: 'public',
      title: 'Adventure Tours'
    }
  },
  {
    path: '/adventure-motorcycle-tours/africa',
    name: 'Africa',
    component: Africa,
    meta: {
      scope: 'public',
      title: 'Africa Motorcycle Tours in Namibia, Zimbabwe, Botswana and South Africa'
    }
  },
  {
    path: '/adventure-motorcycle-tours/canada',
    name: 'Canada',
    component: Canada,
    meta: {
      scope: 'public',
      title: 'Canada Motorcycle Tours in the Rockies and the Yukon'
    }
  },
  {
    path: '/adventure-motorcycle-tours/mongolia',
    name: 'Mongolia',
    component: Mongolia,
    meta: {
      scope: 'public',
      title: 'Tour Mongolia on a Motorcycle with Renedian Adventure'
    }
  },
  {
    path: '/adventure-motorcycle-tours/scotland',
    name: 'Scotland',
    component: Scotland,
    meta: {
      scope: 'public',
      title: 'Tour Scotland on a Motorcycle with Renedian Adventure'
    }
  },
  {
    path: '/adventure-motorcycle-tours/south-america',
    name: 'SouthAmerica',
    component: SouthAmerica,
    meta: {
      scope: 'public',
      title: 'South America Motorcycle Tours - Renedian'
    }
  },
  // ---------------------- Public Tours ----------------------
  {
    path: '/adventure-motorcycle-tours/africa/cape-town-to-victoria-falls',
    name: 'CapeTownToVictoriaFalls',
    component: CapeTownToVictoriaFalls,
    meta: {
      scope: 'public',
      title: 'Cape Town to Victoria Falls tour: All Paved Route',
      description: 'Motorcycle tour from Cape Town, South Africa and travel on oceanside, mountaintop, and desert roads to reach Victoria Falls'
    }
  },
  {
    path: '/adventure-motorcycle-tours/mongolia/gobi-discovery',
    name: 'GobiDiscovery',
    component: GobiDiscovery,
    meta: {
      scope: 'public',
      title: 'Gobi Discovery',
      description: 'A 15 day guided and supported looped motorcycle tour through the famous Gobi desert in the far south of Mongolia.'
    }
  },
  {
    path: '/adventure-motorcycle-tours/mongolia/golden-eagle-festival',
    name: 'GoldenEagleFestival',
    component: GoldenEagleFestival,
    meta: {
      scope: 'public',
      title: 'Golden Eagle Festival',
      description: 'A 15 day guided and supported looped motorcycle tour through the famous Gobi desert in the far south of Mongolia.'
    }
  },
  {
    path: '/adventure-motorcycle-tours/mongolia/golden-eagle-festival',
    name: 'JourneyToUshuaia',
    component: JourneyToUshuaia,
    meta: {
      scope: 'public',
      title: 'Journey to Ushuaia',
      description: 'In the lower half of South America are a few must see stops: the Perito Moreno Glacier, Torres del Paine National Park, Mt Fitz Roy, and the towns of Bariloche and Ushuaia. Connecting all of these with seldom travelled back roads is where our magic comes in.'
    }
  },
  {
    path: '/adventure-motorcycle-tours/canada/north-to-the-dempster-tuk-the-arctic-ocean',
    name: 'NorthToTheYukon',
    component: NorthToTheYukon,
    meta: {
      scope: 'public',
      title: 'North to the Yukon! motorcycle tour',
      description: "Visit northern British Columbia and the Yukon on a motorcycle. A Tour taking you from Kelowna to Klondike Gold Rush City, Dawson"
    }
  },
  {
    path: '/adventure-motorcycle-tours/africa/spectacular-south-west-africa',
    name: 'SpectacularSouthWestAfrica',
    component: SpectacularSouthWestAfrica,
    meta: {
      scope: 'public',
      title: 'Tour from Namibia to Cape Town - Spectacular South West Africa tour',
      description: "Join Renedian on a motorcycle tour from Windhoek, Namibia to Cape Town, South Africa highlighting Namibia's natural wonders."
    }
  },
  {
    path: '/adventure-motorcycle-tours/canada/best-of-canadian-rockies-9-day-express',
    name: 'TheBestOfTheCanadianRockies9',
    component: TheBestOfTheCanadianRockies9,
    meta: {
      scope: 'public',
      title: 'Canadian Rockies 9 Day Express motorcycle tour',
      description: "See the best of the Canadian Rockies on a 9 day motorcycle tour with Renedian Motorcycle Adventures."
    }
  },
  {
    path: '/adventure-motorcycle-tours/canada/best-of-canadian-rockies',
    name: 'TheBestOfTheCanadianRockies12',
    component: TheBestOfTheCanadianRockies12,
    meta: {
      scope: 'public',
      title: 'Best of the Canadian Rockies - 12 Day Supported',
      description: "Discover the best of the Canadian Rockies. Starting and finishing in Kelowna, this tour joins the best motorcycle roads from BC and Alberta"
    }
  },
  {
    path: '/adventure-motorcycle-tours/africa/the-grand-tour',
    name: 'TheGrandTour',
    component: TheGrandTour,
    meta: {
      scope: 'public',
      title: 'The Grand Tour - Experience southern Africa on a Motorcycle',
      description: 'Tour the Cape on a motorcycle, ocean roads, beautiful mountain passes. Enjoy the best hospitality South Africa has to offer.'
    }
  },
  {
    path: '/adventure-motorcycle-tours/africa/two-oceans',
    name: 'TwoOceans',
    component: TwoOceans,
    meta: {
      scope: 'public',
      title: 'Two Oceans - Cape Ocean Roads Motorcycle Tour',
      description: 'Tour the Cape on a motorcycle, ocean roads, beautiful mountain passes. Enjoy the best hospitality South Africa has to offer.'
    }
  },
  {
    path: '/adventure-motorcycle-tours/africa/victoria-falls-to-cape-town',
    name: 'VictoriaFallsToCapeTown',
    component: VictoriaFallsToCapeTown,
    meta: {
      scope: 'public',
      title: 'Tour from Victoria Falls to Cape Town',
      description: "A Tour from Victoria Falls to Cape Town on a motorcycle, visit the Okavango Delta, the sand dunes in Namibia and Cape Town's Garden Route"
    }
  },
  {
    path: '/adventure-motorcycle-tours/africa/waterfalls-and-wildlife',
    name: 'WaterfallsAndWildlife',
    component: WaterfallsAndWildlife,
    meta: {
      scope: 'public',
      title: 'Waterfalls & Wildlife',
      description: 'A paved road motorcycle round-trip tour through Namibia, Botswana, and Zimbabwe. This tour is heavily weighted on game viewing.'
    }
  },
  // ---------------------- Portal Routes ----------------------
  {
    path: '/portal/billing',
    name: 'PortalBilling',
    component: PortalBilling,
    meta: {
      scope: 'private',
      template: 'Portal',
      title: 'Billing - Rider Portal'
    }
  },
  {
    path: '/portal',
    name: 'PortalDashboard',
    component: PortalDashboard,
    meta: {
      scope: 'private',
      template: 'Portal',
      title: 'Dashboard - Rider Portal'
    }
  },
  {
    path: '/portal/login',
    name: 'PortalLogin',
    component: PortalLogin,
    meta: {
      scope: 'public',
      authenticatedRoute: 'PortalDashboard',
      title: 'Portal - Login'
    }
  },
  {
    path: '/portal/profile',
    name: 'PortalProfile',
    component: PortalProfile,
    meta: {
      scope: 'private',
      template: 'Portal',
      title: 'Profile - Rider Portal'
    }
  },
  {
    path: '/portal/tour/:index',
    name: 'PortalTour',
    component: PortalTour,
    meta: {
      scope: 'private',
      template: 'Portal',
      title: 'Tour - Rider Portal'
    }
  },
  // ---------------------- Admin Routes ----------------------
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
    meta: {
      scope: 'public',
      authenticatedRoute: 'AdminDashboard',
      title: 'Admin Login'
    }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Dashboard',
      permissions: ['Manager', 'Guide']
    }
  },
  {
    path: '/admin/pending',
    name: 'AdminPending',
    component: AdminPending,
    meta: {
      scope: 'admin',
      title: 'Admin Pending'
    }
  },
  // ------------ Bookings ------------
  {
    path: '/admin/bookings',
    name: 'AdminBookings',
    component: AdminBookings,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Bookings',
      permissions: ['Manager']
    }
  },
  
  // ------------ Vendors ------------
  {
    path: '/admin/vendors/activities',
    name: 'AdminVendorsActivities',
    component: AdminVendorsActivities,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Vendors - Activities',
      permissions: ['Manager', 'Administration']
    }
  },
  {
    path: '/admin/vendors/activity/:id',
    name: 'AdminVendorsActivity',
    component: AdminVendorsActivity,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Vendors - Activity',
      permissions: ['Manager', 'Administration']
    }
  },
  {
    path: '/admin/vendors/hotels',
    name: 'AdminVendorsHotels',
    component: AdminVendorsHotels,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Vendors - Hotels',
      permissions: ['Manager', 'Administration']
    }
  },
  {
    path: '/admin/vendors/hotel/:id',
    name: 'AdminVendorsHotelEdit',
    component: AdminVendorsHotel,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Vendors - Hotel',
      permissions: ['Manager', 'Administration']
    }
  },
  {
    path: '/admin/vendors/hotel',
    name: 'AdminVendorsHotelAdd',
    component: AdminVendorsHotel,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Vendors - Add Hotel',
      permissions: ['Manager', 'Administration']
    }
  },
  {
    path: '/admin/vendors/restaurant/:id',
    name: 'AdminVendorsRestaurant',
    component: AdminVendorsRestaurant,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Vendors - Restaurant',
      permissions: ['Manager', 'Administration']
    }
  },
  {
    path: '/admin/vendors/restaurants',
    name: 'AdminVendorsRestaurants',
    component: AdminVendorsRestaurants,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Vendors - Restaurants',
      permissions: ['Manager', 'Administration']
    }
  },
  {
    path: '/admin/vendors/vehicles',
    name: 'AdminVendorsVehicles',
    component: AdminVendorsVehicles,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Vendors - Vehicles',
      permissions: ['Manager', 'Administration']
    }
  },

  // ------------ People ------------
  {
    path: '/admin/people/customers',
    name: 'AdminCustomers',
    component: AdminCustomers,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin People - Customers',
      permissions: ['Manager']
    }
  },
  {
    path: '/admin/people/employees',
    name: 'AdminEmployees',
    component: AdminEmployees,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin People - Employees',
      permissions: ['Manager']
    }
  },
  {
    path: '/admin/people/leads',
    name: 'AdminLeads',
    component: AdminLeads,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin People - Leads',
      permissions: ['Manager']
    }
  },

  // ------------ Tours ------------
  {
    path: '/admin/tours',
    name: 'AdminTours',
    component: AdminTours,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Tours',
      permissions: ['Manager']
    }
  },
  {
    path: '/admin/tours/manage/:id',
    name: 'AdminToursManage',
    component: AdminToursManage,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Tours - Manage',
      permissions: ['Manager']
    }
  },
  {
    path: '/admin/tours/templates/:id',
    name: 'AdminToursTemplate',
    component: AdminToursTemplate,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Tours - Template',
      permissions: ['Manager']
    }
  },
  {
    path: '/admin/tours/templates',
    name: 'AdminToursTemplates',
    component: AdminToursTemplates,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Tours - Templates',
      permissions: ['Manager']
    }
  },

  // ------------ Automation ------------
  {
    path: '/admin/automation/email',
    name: 'AdminAutomationEmail',
    component: AdminAutomationEmail,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Automation - Email',
      permissions: ['Manager']
    }
  },
  {
    path: '/admin/automation/ai',
    name: 'AdminAutomationAI',
    component: AdminAutomationAI,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Automation - AI',
      permissions: ['Manager']
    }
  },

  // ------------ Insights ------------
  {
    path: '/admin/insights/costing',
    name: 'AdminInsightsCosting',
    component: AdminInsightsCosting,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Insights - Costing',
      permissions: ['Manager']
    }
  },
  {
    path: '/admin/insights/metrics',
    name: 'AdminInsightsMetrics',
    component: AdminInsightsMetrics,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Insights - Metrics',
      permissions: ['Manager']
    }
  },
  {
    path: '/admin/insights/overview',
    name: 'AdminInsightsOverview',
    component: AdminInsightsOverview,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Insights - Overview',
      permissions: ['Manager']
    }
  },

  // ------------ Guides ------------
  {
    path: '/admin/guide/tours/current',
    name: 'AdminGuideCurrentTour',
    component: AdminGuideCurrentTour,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Guide - Current Tour',
      permissions: ['Guide']
    }
  },
  {
    path: '/admin/guide/profile',
    name: 'AdminGuideProfile',
    component: AdminGuideProfile,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Guide - Profile',
      permissions: ['Guide']
    }
  },
  {
    path: '/admin/guide/tours/upcoming',
    name: 'AdminGuideUpcomingTours',
    component: AdminGuideUpcomingTours,
    meta: {
      scope: 'admin',
      template: 'Admin',
      title: 'Admin Guide - Upcoming Tours',
      permissions: ['Guide']
    }
  },

  // ---------------------- Test ----------------------
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      scope: 'public',
      title: 'Test'
    }
  },

  // ---------------------- System Routes ----------------------
  {
    path: '*',
    name: 'Lost', 
    component: Lost,
    meta: {
      scope: 'public'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  let user = getAuth().currentUser;
  console.log('Router', user)
  switch (to.meta.scope) {
    // ---------------------- Public ----------------------
    case 'public':
      // Special Case: Login Pages
      if (to.name === 'PortalLogin' || to.name === 'AdminLogin') {
        if (user) {
          next({ name: to.meta.authenticatedRoute });
        } else {
          next();
        }
        break;
      }
      // Redirect everything else
      // if (to.name === 'Landing') {
        // Temporary redirect to booking
        // next({ name: 'Booking' });
      // } else {
        next();
      // }
      break;

    // ---------------------- Private ----------------------
    case 'private':
      if (user) {
        next();
      } else {
        next({ name: 'PortalLogin' });
      }
      break;

    // ---------------------- Admin ----------------------
    case 'admin':
      console.log('Accessing admin route');
      if (user) {
        const db = getFirestore();
        const employeeDoc = doc(db, 'employees', user.uid);
        const employee = await getDoc(employeeDoc);
        console.log('employee', employee.data());
        // Check: Employee exists, is active, and has the correct permissions or is a Manager
        if (
          employee.exists() && 
          employee.data().active &&
          (
            to.meta.permissions.includes(employee.data().permissions) ||
            employee.data().permissions === 'Manager'
          )
        ) {
          console.log('Employee is active and has permissions');
          next(); // User is active, has permissions, and is logged in
        } else {
          next({ name: 'AdminPending' });
        }
      } else {
        next({ name: 'AdminLogin' });
      }
      break;

    // ---------------------- Default ----------------------
    default:
      console.log('Default route');
      next();
  }
});

router.afterEach((to) => {
  document.title = (to.meta.title || 'Renedian Motorcycle Tours') + ' | Renedian'
  const descriptionTag = document.querySelector('meta[name="description"]');
  if (descriptionTag) {
    descriptionTag.setAttribute('content', to.meta.description || 'Default Description');
  }
});

export default router