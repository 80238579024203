<template>
  <v-container>
    <!-- <div class="g-font renedian-red--text mb-6 font-size-22">Tour</div> -->
    <v-progress-linear v-if="loading" indeterminate color="amber"></v-progress-linear>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <v-img
      v-if="loaded"
      class="d-flex align-center white--text rounded-image"
      height="180"
      :src="tour.template.images.banner"
    >
      <div class="d-flex flex-column justify-center pa-6">
        <div class="text-h3 white--text font-weight-bold text-shadow">
          {{ tour.template.details.name }}
        </div>
        <div class="font-size-16 white--text">
          {{ tour.schedule.primary.start | humanDate }} - {{ tour.schedule.primary.finish | humanDate }}
        </div>
      </div>
    </v-img>
    <!-- Sub Menu-->
    <v-tabs v-model="tab" v-if="loaded" fixed-tabs>
      <v-tab>
        <v-badge v-if="countCompletedActions < countTotalActions" color="red" dot>
          Action Items
        </v-badge>
        <span v-else>Action Items</span>
      </v-tab>
      <v-tab>Getting Ready</v-tab>
      <v-tab>Road Book**</v-tab>
      <v-tab>Itinerary</v-tab>
      <v-tab disabled>Guides and Crew</v-tab>
      <v-tab disabled>Manage Booking</v-tab>
    </v-tabs>
    
    <v-tabs-items v-model="tab" v-if="loaded">
      <!-- ------------ Action Items ------------ -->
      <v-tab-item>
        <h3 v-if="countCompletedActions < countTotalActions" class="pa-6 pb-0">Required Action Items</h3>
        <v-expansion-panels v-if="countCompletedActions < countTotalActions" v-model="panel" class="pa-6" inset>
          <!-- Passport Information -->
          <v-expansion-panel v-if="!actions.passport.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon>
              Passport Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                label="Full Name (as on passport)"
                v-model="actions.passport.fullName"
              ></v-text-field>
              <v-text-field
                label="Passport Number"
                v-model="actions.passport.number"
              ></v-text-field>
              <!-- Date of Birth -->
              <v-menu
                ref="dobMenu"
                v-model="dobMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    label="Date of Birth"
                    v-model="actions.passport.dob"
                    v-on="on"
                    v-bind="attrs"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="actions.passport.dob"
                  @input="dobMenu = false"
                ></v-date-picker>
              </v-menu>
              <!-- Gender -->
              <v-radio-group
                v-model="actions.passport.gender"
                label="Gender"
                row
              >
                <v-radio label="Male" value="Male"></v-radio>
                <v-radio label="Female" value="Female"></v-radio>
              </v-radio-group>
              <!-- Passport Expiry Date -->
              <v-menu
                ref="expiryMenu"
                v-model="expiryMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    label="Passport Expiry Date"
                    v-model="actions.passport.expiryDate"
                    v-on="on"
                    v-bind="attrs"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="actions.passport.expiryDate"
                  @input="expiryMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                label="Issuing Authority"
                v-model="actions.passport.issuingAuthority"
              ></v-text-field>
              <!-- Upload Passport Photo -->
              <v-file-input
                v-if="!actions.passport.photoURL"
                label="Upload Passport Photo Page"
                v-model="uploads.passportPhoto.file"
                accept="image/*,application/pdf"
                @change="uploadPassportPhoto"
              ></v-file-input>
              <v-progress-linear
                v-if="uploads.passportPhoto.uploading"
                :value="uploads.passportPhoto.progress"
                color="primary"
              ></v-progress-linear>
              <div v-if="actions.passport.photoURL" class="d-flex flex-row align-center">
                Passport Photo Page:
                <v-btn class="" icon small @click="actions.passport.photoURL = ''; actions.passport.photo = ''; uploads.passportPhoto.file = null">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-img
                v-if="actions.passport.photoURL"
                :src="actions.passport.photoURL"
                height="200"
                class="rounded-image"
                contain
                aspect-ratio="1"
                @click="actions.passport.photoURL = ''; actions.passport.photo = ''; uploads.passportPhoto.file = null"
              ></v-img>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.passport" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.passport }}
                </div>
                <v-btn @click="submitPassportInfo" tile color="primary" :loading="actionSaving.passport" :disabled="actionSaving.passport">
                  Save Passport Information
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Driver's License Information (Riders Only) -->
          <v-expansion-panel v-if="!actions.driversLicense.complete && booking.guests[guestIndex].preferences.riding == 'bike'">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Driver's License Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                label="Full Name (as on driver's license)"
                v-model="actions.driversLicense.fullName"
              ></v-text-field>
              <v-text-field
                label="Driver's License Number"
                v-model="actions.driversLicense.number"
              ></v-text-field>
              <!-- License Expiry Date -->
              <v-menu
                ref="licenseExpiryMenu"
                v-model="licenseExpiryMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    prepend-icon="mdi-calendar"
                    label="Driver's License Expiry Date"
                    v-model="actions.driversLicense.expiryDate"
                    v-on="on"
                    v-bind="attrs"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="actions.driversLicense.expiryDate"
                  @input="licenseExpiryMenu = false"
                ></v-date-picker>
              </v-menu>
              <!-- Driver's License Front Photo -->
              <v-file-input
                v-if="!actions.driversLicense.frontPhotoURL"
                label="Upload Front of Driver's License"
                v-model="uploads.licenseFrontPhoto.file"
                accept="image/*,application/pdf"
                @change="uploadLicenseFrontPhoto"
              ></v-file-input>
              <v-progress-linear
                v-if="uploads.licenseFrontPhoto.uploading"
                :value="uploads.licenseFrontPhoto.progress"
                color="primary"
              ></v-progress-linear>
              <div v-if="actions.driversLicense.frontPhotoURL" class="d-flex flex-row align-center">
                Front of Driver's License:
                <v-btn class="" icon small @click="actions.driversLicense.frontPhotoURL = ''; actions.driversLicense.frontPhotoURL = ''; uploads.licenseFrontPhoto.file = null">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-img
                v-if="actions.driversLicense.frontPhotoURL"
                :src="actions.driversLicense.frontPhotoURL"
                height="200"
                class="rounded-image"
                contain
                aspect-ratio="1"
                @click="actions.driversLicense.frontPhotoURL = ''; actions.driversLicense.frontPhotoURL = ''; uploads.licenseFrontPhoto.file = null"
              ></v-img>
              <!-- Driver's License Back Photo -->
              <v-file-input
                v-if="!actions.driversLicense.backPhotoURL"
                label="Upload Back of Driver's License"
                v-model="uploads.licenseBackPhoto.file"
                accept="image/*,application/pdf"
                @change="uploadLicenseBackPhoto"
              ></v-file-input>
              <v-progress-linear
                v-if="uploads.licenseBackPhoto.uploading"
                :value="uploads.licenseBackPhoto.progress"
                color="primary"
              ></v-progress-linear>
              <div v-if="actions.driversLicense.backPhotoURL" class="d-flex flex-row align-center">
                Back of Driver's License:
                <v-btn class="" icon small @click="actions.driversLicense.backPhotoURL = ''; actions.driversLicense.backPhotoURL = ''; uploads.licenseBackPhoto.file = null">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-img
                v-if="actions.driversLicense.backPhotoURL"
                :src="actions.driversLicense.backPhotoURL"
                height="200"
                class="rounded-image"
                contain
                aspect-ratio="1"
                @click="actions.driversLicense.backPhotoURL = ''; actions.driversLicense.backPhotoURL = ''; uploads.licenseBackPhoto.file = null"
              ></v-img>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.driversLicense" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.driversLicense }}
                </div>
                <v-btn @click="submitDriversLicenseInfo" tile color="primary" :loading="actionSaving.driversLicense" :disabled="actionSaving.driversLicense">
                  Save Driver's License Information
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Preferences -->
          <v-expansion-panel v-if="!actions.preferences.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Preferences
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- T-Shirt Size -->
              <v-select
                :items="tshirtSizes"
                label="What is your t-shirt size?"
                v-model="actions.preferences.tshirtSize"
              ></v-select>
              <!-- Dietary Requirement -->
              <v-select
                :items="dietaryOptions"
                label="Do you have any specific dietary requirements?"
                v-model="actions.preferences.dietaryRequirement"
              ></v-select>
              <v-text-field
                v-if="actions.preferences.dietaryRequirement === 'Other'"
                label="Specify other dietary requirement"
                v-model="actions.preferences.dietaryOther"
              ></v-text-field>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.preferences" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.preferences }}
                </div>
                <v-btn @click="submitPreferences" tile color="primary" :loading="actionSaving.preferences" :disabled="actionSaving.preferences">
                  Save Preferences
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Medical Information -->
          <v-expansion-panel v-if="!actions.medical.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Medical Information
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Allergies -->
              <v-radio-group
                v-model="actions.medical.hasAllergies"
                label="Do you have any allergies?"
                row
              >
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
              <v-text-field
                v-if="actions.medical.hasAllergies"
                label="Please specify your allergies"
                v-model="actions.medical.allergies"
              ></v-text-field>
              <!-- Medical Needs -->
              <v-radio-group
                v-model="actions.medical.hasMedicalNeeds"
                label="Do you have any specific medical needs (ie. CPAP machine, refrigerated medication, etc.)?"
                row
              >
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
              <v-text-field
                v-if="actions.medical.hasMedicalNeeds"
                label="Please specify your medical needs"
                v-model="actions.medical.medicalNeeds"
              ></v-text-field>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.medical" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.medical }}
                </div>
                <v-btn @click="submitMedicalInfo" tile color="primary" :loading="actionSaving.medical" :disabled="actionSaving.medical">
                  Save Medical Information
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Emergency Contact -->
          <v-expansion-panel v-if="!actions.emergencyContact.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Emergency Contact
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                label="Full Name"
                v-model="actions.emergencyContact.name"
              ></v-text-field>
              <v-text-field
                label="Contact Number"
                v-model="actions.emergencyContact.phone"
              ></v-text-field>
              <v-text-field
                label="Email Address"
                v-model="actions.emergencyContact.email"
              ></v-text-field>
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.emergencyContact" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.emergencyContact }}
                </div>
                <v-btn @click="submitEmergencyContactInfo" tile color="primary" :loading="actionSaving.emergencyContact" :disabled="actionSaving.emergencyContact">
                  Save Emergency Contact Information
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Waiver -->
          <v-expansion-panel v-if="!actions.waiver.complete">
            <v-expansion-panel-header>
              <v-icon left style="flex: none">mdi-checkbox-blank-outline</v-icon> 
              Liability Waiver
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="waiverForm">
                <v-card class="pa-6">
                  <!-- Waiver Text with Paragraphs -->
                  <div ref="waiverContent" class="waiver">
                    <!-- Header Logo -->
                    <div class="waiver-centered">
                      <img src="@/assets/images/renedian-logo.png" class="waiver-logo">
                    </div>
                    <!-- Waiver Intro -->
                    <div class="waiver-section waiver-centered">
                      <p class="waiver-paragraph waiver-bold-text">PLEASE READ THIS DOCUMENT THOROUGHLY AS IT WILL AFFECT YOUR LEGAL RIGHTS</p>
                      <p class="waiver-paragraph waiver-bold-text">RELEASE OF LIABILITY, WAIVER OF CLAIMS, ASSUMPTION OF RISKS AND INDEMNITY AGREEMENT</p>
                      <p class="waiver-paragraph waiver-bold-text">(hereinafter referred to as the "Release Agreement")</p>
                      <p class="waiver-paragraph waiver-bold-text">BY SIGNING THIS DOCUMENT YOU WILL WAIVE CERTAIN LEGAL RIGHTS, INCLUDING THE RIGHT TO SUE OR CLAIM COMPENSATION FOLLOWING AN ACCIDENT</p>
                      <p class="waiver-emphasis-text">PLEASE READ CAREFULLY!</p>
                    </div>
                    <!-- Paragraph 1 -->
                    <div class="waiver-section">
                      <p class="waiver-paragraph">I (the "<span class="waiver-bold-text">Motorcyclist</span>") sign on {{ fullDate }} this RELEASE AND WAIVER OF LIABILITY (the "<span class="waiver-bold-text">Release</span>") in favour of Renedian Adventures Ltd. and all related or associated companies and their respective owners, directors, officers, employees, agents, representatives, instructors, guides, contractors, subcontractors, sponsors, successors and assigns (hereinafter collectively referred to as the "<span class="waiver-bold-text">Releasees</span>")</p>
                      <p class="waiver-paragraph">In this Release, the term "<span class="waiver-bold-text">Motorcycling</span>" shall include, but is not limited to: the rental and use of motorcycles, the rental and use of related safety and personal protective equipment, the use of my personal motorcycle, and all related activities, services, and use of facilities, equipment, and/or terrain either provided by or arranged by the Releasees, including guided and self-guided programs, and including the provision of accommodations and food/beverage selections.</p>
                    </div>
                    <!-- Paragraph 2 -->
                    <div class="waiver-section">
                      <p class="waiver-section-heading">ACKNOWLEDGEMENT - SAFETY</p>
                      <p class="waiver-section-subheading">THE USE OF AN APPROVED HELMET IS MANDATORY</p>
                      <p class="waiver-paragraph">I acknowledge that I have been advised I must, and I will, wear the appropriate safety or protective equipment while participating in Motorcycling, including helmets and other protective gear. Using proper safety equipment may be mandatory under applicable governmental law, and I am aware that the use of the Motorcycle is subject to applicable governmental motor vehicle regulations.</p>
                      <p class="waiver-paragraph">I am aware that the physical exertion required by Motorcycling and forces exerted on the body can activate or aggravate pre- existing injuries, conditions, symptoms, or congenital defects. I have been advised to seek medical advice if I know or suspect that my physical condition may be incompatible with Motorcycling.</p>
                      <p class="waiver-paragraph">I acknowledge that I will not be under the influence of drugs and/or alcohol while participating in Motorcycling.</p>
                      <v-checkbox v-model="actions.waiver.acknowledgement.safety" label="I confirm and acknowledge having read and understood the above" />
                    </div>
                    <!-- Paragraph 3 -->
                    <div class="waiver-section">
                      <p class="waiver-section-heading">DESCRIPTION AND ASSUMPTION OF RISKS</p>
                      <p class="waiver-paragraph">There are many potential <span class="font-weight-bold">RISKS</span>, <span class="font-weight-bold">DANGERS</span> and <span class="font-weight-bold">HAZARDS</span> associated with Motorcycling, including the <span class="font-weight-bold">RISK OF SEVERE INJURY OR DEATH</span>.</p>
                      <p class="waiver-paragraph">The roads and pathways used for Motorcycling can be uncontrolled, unmarked, in poor condition, and not inspected, and involves many risks, dangers and hazards. The Releasees make no representations as to whether the roadways are safe.</p>
                      <p class="waiver-paragraph">Risks, dangers and hazards associated with Motorcycling may include, <span class="font-weight-bold">but are not limited to</span>:</p>
                      <p class="waiver-paragraph">Accidents which occur during transportation to and from the location of participation; Rockfalls, icefall, avalanches of snow, ice and rock triggered by natural forces, by persons travelling through the area or other causes; extreme variation in road conditions and terrain, including hidden or exposed holes or depressions, cracks, uneven surfaces, gravel roads, ice, snow, water or slippery conditions; falls, trips and slips; limited visibility; variable, severe or rapidly changing weather with little or no warning including storms, high winds and whiteouts; dangerous and unpredictable road conditions; hypothermia; frostbite; hyperthermia; dehydration; loss of balance or control and falls, particularly on steep, slippery, uneven or difficult terrain; errors of judgment; lack of preparation; inadequate, inappropriate, improperly prepared or adjusted equipment; equipment failure, malfunction or loss; fires (including burns and damage to property therefrom) loss or damage to personal property; failure to act safely or within one's ability or within a designated area; impact or collision with vehicles, other persons, or objects; encounters with domestic or wild animals; animal bites; becoming lost, going off route or becoming separated from one's party; dangerous driving conditions; civil strife, crime; travel over poor roads; and infectious and communicable disease, including but not limited to COVID-19, contracted through viruses, bacteria, parasites, and fungi, which may be transmitted through direct or indirect contact or the negligence of other persons.</p>
                      <p class="waiver-paragraph">Communication, including cellular reception as well as satellite reception, in the areas where Motorcycling occurs may not be reliable or available, and it may be difficult or impossible to communicate with the outside from within such areas, and in the event of an accident, rescue and medical treatment may not be readily available or available.</p>
                      <p class="waiver-paragraph">I am aware that participation in Motorcycling also <span class="font-weight-bold">MAY INCLUDE RISKS INCLUDING NEGLIGENCE ON THE PART OF THE RELEASEES. I UNDERSTAND THAT NEGLIGENCE INCLUDES FAILURE ON THE PART OF THE RELEASEES TO TAKE REASONABLE STEPS TO SAFEGUARD OR PROTECT ME FROM THE RISKS, DANGERS AND HAZARDS OF PARTICIPATING IN OUTDOOR ACTIVITIES. I FREELY ACCEPT AND FULLY ASSUME ALL SUCH RISKS, DANGERS AND HAZARDS AND THE POSSIBILITY OF PERSONAL INJURY, DEATH, PROPERTY DAMAGE AND LOSS RESULTING THEREFROM</span>.</p>
                      <v-checkbox v-model="actions.waiver.acknowledgement.risks" label="I confirm and acknowledge having read and understood the above" />
                    </div>
                    <!-- Paragraph 4 -->
                    <div class="waiver-section">
                      <p class="text-h6">RELEASE OF LIABILITY, WAIVER OF CLAIMS AND INDEMNITY AGREEMENT</p>
                      <p>In consideration of the RELEASEES agreeing to my participation in Motorcycling, I do hereby freely, voluntarily, and without duress agree as follows:</p>
                      <ol>
                        <li class="ml-6"><span class="font-weight-bold">TO WAIVE ANY AND ALL CLAIMS</span> that I have or may in the future have against the RELEASEES <span class="font-weight-bold">AND TO RELEASE THE RELEASEES</span> from any and all liability for any loss, damage, expense or injury, including death, that I may suffer or that my next of kin may suffer, as a result of my participation in Motorcycling, DUE TO ANY CAUSE WHATSOEVER, INCLUDING NEGLIGENCE, BREACH OF CONTRACT, OR BREACH OF ANY STATUTORY OR OTHER DUTY OF CARE ON THE PART OF THE RELEASEES. I UNDERSTAND THAT NEGLIGENCE INCLUDES FAILURE ON THE PART OF THE RELEASEES TO TAKE REASONABLE STEPS TO SAFEGUARD OR PROTECT ME FROM THE RISKS, DANGERS AND HAZARDS OF PARTICIPATING IN THE RENTAL OPPORTUNITY INCLUDING THE RISKS REFERRED TO ABOVE;</li>
                        <li class="ml-6">I agree TO HOLD HARMLESS AND INDEMNIFY THE RELEASEES from any and all liability for any property damage or personal injury to any third party resulting from my participation in Motorcycling, as well as any loss of or damage to personal property left in or on the Motorcycle.</li>
                        <li class="ml-6">I agree TO HOLD HARMLESS AND INDEMNIFY THE RELEASEES from any and all expenses incurred by the Releasees, including legal fees and/or settlement payments and/or Court or Tribunal awards, as a result of legal or administrative actions brought by myself (or brought on behalf of an infant, if I am executing this Release as the parent/guardian on behalf the said infant participant of Motorcycling).</li>
                        <li class="ml-6">Should I (or the minor if I am executing this Release as the parent/guardian on behalf of the said minor participant) require first aid or emergency medical treatment as a result of accident, illness or any medical issue while participating in Motorcycling, I consent to such treatment as deemed necessary by the Releasees.</li>
                        <li class="ml-6">Should I (or the minor if I am executing this Release as the parent/guardian on behalf of the said minor participant) require non-scheduled or emergency evacuation, rescue, first aid or medical treatment, I agree that I will bear all costs and expenses associated with such non-scheduled or emergency evacuation, rescue, first aid or medical treatment, and these costs and expenses will not be covered by the Releasees.</li>
                        <li class="ml-6">I agree TO HOLD HARMLESS AND INDEMNIFY THE RELEASEES from any and all liability on account of any first aid, treatment or services rendered in connection with my participation in Motorcycling.</li>
                        <li class="ml-6">I understand that the Releasees do not assume any responsibility for or obligation to provide financial assistance or other assistance, included but not limited to medical, health or disability insurance.</li>
                        <li class="ml-6">I certify that no qualified medical professional has advised me to not participate in Outdoor Activities.</li>
                        <li class="ml-6">This Release shall be effective and binding upon my heirs, next of kin, executors, administrators, assigns and representatives, in the event of my death of incapacity;</li>
                        <li class="ml-6">This Release and any rights, duties and obligations as between the parties to this Release shall be governed by and interpreted solely in accordance with the laws of the Province of Alberta and no other jurisdiction; and</li>
                        <li class="ml-6">Any litigation involving the parties to this Release shall be brought solely within the Province of Alberta and shall be within the exclusive jurisdiction of the Courts of the Province of Alberta.</li>
                      </ol>
                      <v-checkbox v-model="actions.waiver.acknowledgement.indemnity" label="I confirm and acknowledge having read and understood the above" />
                    </div>
                    <!-- Paragraph 5 -->
                    <div class="waiver-section">
                      <p class="text-h6">PHOTO/VIDEO RELEASE</p>
                      <p class="waiver-paragraph">I understand that during Motorcycling I may be photographed or videotaped and I consent to such photographs and/or video taken of me being used for advertising, promotional or marketing purposes, whether online, in print, on the Releasees' social media or anywhere else where they choose to use such material.</p>
                      <v-checkbox v-model="actions.waiver.acknowledgement.release" label="I confirm and acknowledge having read and understood the above" />
                    </div>
                    <!-- Paragraph 6 -->
                    <div class="waiver-section">
                      <p class="text-h6">GENERAL</p>
                      <p>If any portion of this agreement shall be held to be invalid or unenforceable, such provision shall be stricken and the remainder of the agreement shall remain in full force and effect to accomplish the intent and purpose of the parties. The parties agree to negotiate the severed provision to bring it within the applicable legal requirements to the extent possible.</p>
                      <p>In entering into this Release, I am not relying on any oral or written representations or statements made by the Releasees with respect to the safety of participating in Motorcycling, other than what is set forth in this Release.</p>
                    </div>
                    <!-- Paragraph 7 -->
                    <div class="waiver-section">
                      <p class="text-h6">CONFIRMATION AND ACKNOWLEDGEMENT</p>
                      <p><span class="font-weight-bold">I confirm that I have read and understand this Release Agreement and by signing it voluntarily I am agreeing to abide by these terms. I am aware that by signing this Release Agreement I am waiving certain legal rights which I or my heirs, executors, administrators, assigns, and representatives may have against the Releasees.</span></p>
                    </div>
                    <!-- Paragraph 8 -->
                    <div class="waiver-section">
                      <p><span class="font-weight-bold">BY SIGNING THIS DOCUMENT, WHETHER IN ELECTRONIC FORMAT OR OTHERWISE, I ACKNOWLEDGE, AGREE AND CERTIFY AS FOLLOWS:</span></p>
                      <p><span class="font-weight-bold ml-6">a. I HAVE READ AND REVIEWED THE RELEASE AGREEMENT AND FULLY UNDERSTAND AND AGREE TO ITS TERMS;</span></p>
                      <p><span class="font-weight-bold ml-6">b. THE ABOVE INFORMATION IS TRUE AND CORRECT IN ALL RESPECTS AS OF THE DATE AND TIME THAT I HAVE COMPLETED THE WAIVER;</span></p>
                      <p><span class="font-weight-bold ml-6">c. I AM AWARE THAT BY SIGNING THIS RELEASE AGREEMENT I AM WAIVING CERTAIN LEGAL RIGHTS WHICH I OR MY HEIRS, EXECUTORS, ADMINISTRATORS, ASSIGNS, AND REPRESENTATIVES MAY HAVE AGAINST THE RELEASEES; AND</span></p>
                      <p><span class="font-weight-bold ml-6">d. I HAVE THE POWER AND AUTHORITY TO AGREE TO THE RELEASE AGREEMENT.</span></p>
                    </div>
                    <!-- Signature Pad -->
                    <div class="waiver-section">
                      <p>Please sign below:</p>
                      <div v-if="!actions.waiver.complete" class="d-flex flex-column">
                        <div class="d-flex flex-row">
                          <canvas
                            ref="signatureCanvas"
                            class="signature-canvas"
                            width="500"
                            height="150"
                            @mousedown="startDrawing"
                            @mousemove="draw"
                            @mouseup="stopDrawing"
                            @mouseleave="stopDrawing"
                            @touchstart="startDrawing"
                            @touchmove="draw"
                            @touchend="stopDrawing"
                          ></canvas>
                          <div class="d-flex flex-column ml-1">
                            <v-btn @click="clearCanvas()" icon>
                              <v-icon>mdi-eraser</v-icon>
                            </v-btn>
                            <!-- <v-btn @click="confirmSignature()" color="green" icon>
                              <v-icon>mdi-check</v-icon>
                            </v-btn> -->
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <img
                          :src="actions.waiver.signature.image"
                          alt="Signature"
                          class="signature-image"
                        />
                      </div>
                      <div class="mt-4" style="width: 500px">
                        <v-text-field v-model="actions.waiver.signature.name" label="Full Name"outlined></v-text-field>
                      </div>
                    </div>
                  </div>
                  <!-- {{ actions.waiver }} -->
                </v-card>
              </v-form>
              <!-- Waiver content here -->
              <div class="d-flex flex-row align-center mt-6">
                <div class="flex-1 text-caption red--text">
                  <v-icon v-if="actionErrors.waiver" left color="red">mdi-alert</v-icon>
                  {{ actionErrors.waiver }}
                </div>
                <v-btn @click="submitWaiver" tile color="primary" :loading="actionSaving.waiver" :disabled="actionSaving.waiver">
                  Accept and Submit Waiver
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Completed Actions -->
        <h3 v-if="countCompletedActions > 0" class="pa-6 pb-0">Completed Action Items</h3>
        <v-card v-if="countCompletedActions > 0" class="ma-6">
          <div v-if="actions.passport.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Passport Information</div>
            <v-btn icon @click="actions.passport.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.driversLicense.complete && booking.guests[guestIndex].preferences.riding == 'bike'" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Driver's License Information</div>
            <v-btn icon @click="actions.driversLicense.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.preferences.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Preferences</div>
            <v-btn icon @click="actions.preferences.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.medical.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Medical Information</div>
            <v-btn icon @click="actions.medical.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.emergencyContact.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Emergency Contact</div>
            <v-btn icon @click="actions.emergencyContact.complete = false"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
          <div v-if="actions.waiver.complete" class="d-flex flex-row px-6 py-3 align-center completed-action">
            <v-icon left style="flex: none" color="green">mdi-checkbox-marked</v-icon>
            <div class="flex-1">Liability Waiver</div>
          </div>
        </v-card>
      </v-tab-item>
      <!-- ------------ Getting Ready ------------ -->
      <v-tab-item>
        <div class="pa-6">
          <h3 class="mb-4">Getting Ready</h3>
          <iframe src="https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Fdocuments%2Ftest-template.pdf?alt=media&token=da1557da-8f5f-4f51-9a87-3b852dc30c6d" style="width: 100%; height: 600px;"></iframe>
        </div>
      </v-tab-item>
      <!-- ------------ Road Book ------------ -->
      <!-- https://github.com/ts1/flipbook-vue?tab=readme-ov-file -->
      <v-tab-item>
        <div class="pa-6">
          <h3 class="mb-4">Road Book</h3>    
          <flipbook class="flipbook" :pages="pages" v-slot="flipbook" :zooms="[1,2]">
            <div class="d-flex justify-center align-center mb-4">
              <v-btn text @click="flipbook.flipLeft" :disabled="!flipbook.canFlipLeft">Previous Page</v-btn>
              <div class="mx-4"><strong>{{ flipbook.page }}</strong> of <strong>{{ flipbook.numPages }}</strong></div>
              <v-btn text @click="flipbook.flipRight" :disabled="!flipbook.canFlipRight">Next Page</v-btn>
            </div>
          </flipbook>
        </div>
      </v-tab-item>
      <!-- ------------ Itinerary ------------ -->
      <v-tab-item>
        {{ tour.template.itinerary }}
      </v-tab-item>
      <!-- ------------ Guides & Crew ------------ -->
      <v-tab-item>
        <!-- Existing content -->
      </v-tab-item>
      <!-- Manage Booking -->
      <v-tab-item class="pa-6">
        {{ booking }}
        <v-alert type="info">
          <ul>
            <li>Summary of booking</li>
            <li>Vehicle</li>
            <li>Accommodation</li>
            <li>Dietary</li>
            <li>Make a payment?</li>
          </ul>
        </v-alert>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar v-model="snackbar" color="green">
      Your changes were saved successfully
    </v-snackbar>
  </v-container>
</template>

<script>
import { getFirestore, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import axios from 'axios';
// import Flipbook from '@/libs/flipbook-vue/vue2';
// import Flipbook from '@/libs/flipbook-vue/dist/vue2/flipbook.js';
import Flipbook from 'flipbook-vue/vue2'


export default {
  name: 'PortalTour',
  props: ['user', 'bookings'],
  components: { Flipbook },
  data() {
    return {
      // Common
      loading: true,
      tab: 0,
      error: '',
      snackbar: false,
      // Booking and tour data
      bookingIndex: null,
      guestIndex: null,
      booking: null,
      tour: null,
      // Action Items
      panel: null,
      // Date pickers
      dobMenu: false,
      expiryMenu: false,
      licenseExpiryMenu: false,
      // File inputs
      passportPhoto: null,
      driversLicenseFrontPhoto: null,
      driversLicenseBackPhoto: null,
      // PDF
      pages: [
        null,
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_1.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_2.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_3.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_4.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_5.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_6.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_7.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_8.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_9.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_10.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_11.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_12.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_13.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_14.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_15.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_16.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_17.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_18.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_19.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_20.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_21.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_22.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_23.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_24.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_25.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_26.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_27.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_28.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_29.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_30.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_31.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_32.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_33.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_34.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_35.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_36.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_37.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_38.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_39.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_40.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_41.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_42.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_43.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_44.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_45.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_46.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_47.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_48.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_49.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_50.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_51.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_52.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_53.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_54.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_55.png?alt=media",
        "https://firebasestorage.googleapis.com/v0/b/renedian-development.appspot.com/o/public%2Froadbooks%2FAF-GT%2Fpage_56.png?alt=media"
    ],
      // Options
      tshirtSizes: [
        'Unisex XS',
        'Unisex S',
        'Unisex M',
        'Unisex L',
        'Unisex XL',
        'Unisex 2XL',
        'Unisex 3XL',
      ],
      dietaryOptions: [
        'No',
        'Vegetarian',
        'Vegan',
        'Gluten-Free',
        'Dairy-Free',
        'Other',
      ],
      // File uploads
      uploads: {
        passportPhoto: {
          file: null,
          uploading: false,
          progress: 0,
        },
        licenseFrontPhoto: {
          file: null,
          uploading: false,
          progress: 0,
        },
        licenseBackPhoto: {
          file: null,
          uploading: false,
          progress: 0,
        },
      },
      // Default actions structure
      defaultActionObject: {
        passport: {
          complete: false,
          fullName: '',
          number: '',
          dob: '',
          gender: '',
          expiryDate: '',
          issuingAuthority: '',
          photo: '',
          photoURL: '',
        },
        driversLicense: {
          complete: false,
          fullName: '',
          number: '',
          expiryDate: '',
          frontPhotoURL: '',
          frontPhoto: '',
          backPhotoURL: '',
          backPhoto: '',
        },
        preferences: {
          complete: false,
          tshirtSize: '',
          dietaryRequirement: '',
          dietaryOther: '',
        },
        medical: {
          complete: false,
          hasAllergies: null,
          allergies: '',
          hasMedicalNeeds: null,
          medicalNeeds: '',
        },
        emergencyContact: {
          complete: false,
          name: '',
          phone: '',
          email: '',
        },
        waiver: {
          complete: false,
          acknowledgement: {
            safety: false,
            risks: false,
            indemnity: false,
            release: false,
          },
          signature: {
            name: '',
            date: '',
            image: null,
          }
        },
      },
      // Action Errors
      actionErrors: {
        passport: '',
        driversLicense: '',
        preferences: '',
        medical: '',
        emergencyContact: '',
        waiver: '',
      },
      // Action Saving
      actionSaving: {
        passport: false,
        driversLicense: false,
        preferences: false,
        medical: false,
        emergencyContact: false,
        waiver: false,
      },
      // Signature
      isDrawing: false,
      points: [], // To store points for smoother lines
      signatureData: null, // Holds the saved signature as a Base64 image
    };
  },
  methods: {
    initialize(source) {
      this.loading = true
      this.error = ''
      // Check for booking and guest index
      if (this.bookings.length == 0) {
        return;
      }
      // Extract parameters
      this.bookingIndex = parseInt(this.$route.params?.index);
      this.tab = this.$route.params?.tab;
      if (this.bookingIndex >= 0) {        
      } else {
        this.error = 'Invalid booking index';
        return
      }
      // Set Booking
      this.loading = true;
      this.booking = this.bookings[this.bookingIndex];
      if (!this.booking) {
        this.error = 'Booking not found';
        this.loading = false;
        return;
      }
      // Actions are unique to each guest so we locate the guest index first
      // Match this.user.profile.email to booking.guests[0].contact.email or booking.guests[1].contact.email
      this.guestIndex = this.booking.guests.findIndex(guest => guest.contact.email === this.user.profile.email);
      if (this.guestIndex === -1) {
        this.error = 'Guest not found in booking';
        this.loading = false;
        return;
      }
      // Set the default actions object
      if (this.booking.guests[this.guestIndex].actions.length == 0) {
        this.booking.guests[this.guestIndex].actions = cloneDeep(this.defaultActionObject);
      }
      // Load Tour
      const db = getFirestore();
      const docRef = doc(db, 'tours', this.booking.tour.id);
      onSnapshot(docRef, (doc) => {
        this.tour = doc.data();
        this.tour.id = doc.id;
        this.loading = false;
      });
    },
    saveBooking() {
      // ------------ Save Booking to Firestore ------------
      const db = getFirestore();
      const docRef = doc(db, 'bookings', this.booking.id);
      return updateDoc(docRef, this.booking);
    },
    uploadPassportPhoto(file) {
      console.log(file)
      this.uploads.passportPhoto.uploading = true;
      this.uploads.passportPhoto.progress = 0;
      this.actions.passport.photoURL = '';
      this.actions.passport.photo = '';
      if (file == null) {
        this.uploads.passportPhoto.uploading = false;
        return;
      }
      const storage = getStorage();
      const timestamp = new Date().getTime();
      // const photoRef = ref(storage, `bookings/${this.booking.id}/guests/${this.guestIndex}/passport-photo/${timestamp}_${file.name}`);
      const photoRef = ref(storage, `bookings/${this.booking.id}/guest-${this.guestIndex}/passport-photo-${timestamp}-${file.name}`);

      const task = uploadBytesResumable(photoRef, file);

      task.on(
        'state_changed',
        (snapshot) => {
          this.uploads.passportPhoto.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.actionErrors.passport = 'Failed to upload passport photo';
          this.uploads.passportPhoto.uploading = false;
          console.error('Upload error:', error);
        },
        () => {
          getDownloadURL(task.snapshot.ref)
            .then((downloadURL) => {
              this.actions.passport.photoURL = downloadURL;
              this.actions.passport.photo = file.name;
              this.uploads.passportPhoto.uploading = false;
            })
            .catch((error) => {
              this.actionErrors.passport = 'Failed to retrieve download URL';
              this.uploads.passportPhoto.uploading = false;
              console.error('Download URL error:', error);
            });
        }
      );
    },
    uploadLicenseFrontPhoto(file) {
      this.uploads.licenseFrontPhoto.uploading = true;
      this.uploads.licenseFrontPhoto.progress = 0;
      this.actions.driversLicense.frontPhotoURL = '';
      this.actions.driversLicense.frontPhoto = '';
      if (file == null) {
        this.uploads.licenseFrontPhoto.uploading = false;
        return;
      }
      const storage = getStorage();
      const timestamp = new Date().getTime();
      // const photoRef = ref(storage, `bookings/${this.booking.id}/guest-${this.guestIndex}/license_front_${timestamp}_${file.name}`);
      const photoRef = ref(storage, `bookings/${this.booking.id}/guest-${this.guestIndex}/license-front-${timestamp}-${file.name}`);

      const task = uploadBytesResumable(photoRef, file);

      task.on(
        'state_changed',
        (snapshot) => {
          this.uploads.licenseFrontPhoto.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.actionErrors.driversLicense = 'Failed to upload passport photo';
          this.uploads.licenseFrontPhoto.uploading = false;
          console.error('Upload error:', error);
        },
        () => {
          getDownloadURL(task.snapshot.ref)
            .then((downloadURL) => {
              this.actions.driversLicense.frontPhotoURL = downloadURL;
              this.actions.driversLicense.frontPhoto = file.name;
              this.uploads.licenseFrontPhoto.uploading = false;
            })
            .catch((error) => {
              this.actionErrors.driversLicense = 'Failed to retrieve download URL';
              this.uploads.licenseFrontPhoto.uploading = false;
              console.error('Download URL error:', error);
            });
        }
      );
    },
    uploadLicenseBackPhoto(file) {
      this.uploads.licenseBackPhoto.uploading = true;
      this.uploads.licenseBackPhoto.progress = 0;
      this.actions.driversLicense.backPhotoURL = '';
      this.actions.driversLicense.backPhoto = '';
      if (file == null) {
        this.uploads.licenseBackPhoto.uploading = false;
        return;
      }
      const storage = getStorage();
      const timestamp = new Date().getTime();
      // const photoRef = ref(storage, `bookings/${this.booking.id}/guests/${this.guestIndex}/license-photo/${timestamp}_${file.name}`);
      const photoRef = ref(storage, `bookings/${this.booking.id}/guest-${this.guestIndex}/license-back-${timestamp}-${file.name}`);

      const task = uploadBytesResumable(photoRef, file);

      task.on(
        'state_changed',
        (snapshot) => {
          this.uploads.licenseBackPhoto.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.actionErrors.driversLicense = 'Failed to upload passport photo';
          this.uploads.licenseBackPhoto.uploading = false;
          console.error('Upload error:', error);
        },
        () => {
          getDownloadURL(task.snapshot.ref)
            .then((downloadURL) => {
              this.actions.driversLicense.backPhotoURL = downloadURL;
              this.actions.driversLicense.backPhoto = file.name;
              this.uploads.licenseBackPhoto.uploading = false;
            })
            .catch((error) => {
              this.actionErrors.driversLicense = 'Failed to retrieve download URL';
              this.uploads.licenseBackPhoto.uploading = false;
              console.error('Download URL error:', error);
            });
        }
      );
    },
    async submitPassportInfo() {
      // 1. Validate Passport data
      this.actionErrors.passport = '';
      this.actionSaving.passport = true;
      if (!this.actions.passport.fullName) {  
        this.actionErrors.passport = 'Full Name is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.number) {
        this.actionErrors.passport = 'Passport Number is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.dob) {
        this.actionErrors.passport = 'Date of Birth is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.gender) {
        this.actionErrors.passport = 'Gender is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.expiryDate) {
        this.actionErrors.passport = 'Expiry date is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.issuingAuthority) {
        this.actionErrors.passport = 'Issuing Authority is required';
        this.actionSaving.passport = false;
        return;
      }
      if (!this.actions.passport.photoURL) {
        this.actionErrors.passport = 'Passport photo is required';
        this.actionSaving.passport = false;
        return;
      }
      // 2. Save booking
      this.actions.passport.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.passport = false;
      }).catch((error) => {
        this.actionErrors.passport = 'Failed to save booking';
        this.actionSaving.passport = false;
        this.actions.passport.complete = false;
      });
    },
    submitDriversLicenseInfo() {
      this.actionErrors.driversLicense = '';
      this.actionSaving.driversLicense = true;
      // 1. Validate Driver's License data
      if (!this.actions.driversLicense.fullName) {
        this.actionErrors.driversLicense = 'Full Name is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      if (!this.actions.driversLicense.number) {
        this.actionErrors.driversLicense = 'Driver\'s License Number is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      if (!this.actions.driversLicense.expiryDate) {
        this.actionErrors.driversLicense = 'Expiry date is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      if (!this.actions.driversLicense.frontPhotoURL) {
        this.actionErrors.driversLicense = 'Front photo is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      if (!this.actions.driversLicense.backPhotoURL) {
        this.actionErrors.driversLicense = 'Back photo is required';
        this.actionSaving.driversLicense = false;
        return;
      }
      // 2. Save booking
      this.actions.driversLicense.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.driversLicense = false;
      }).catch((error) => {
        this.actionErrors.driversLicense = 'Failed to save booking';
        this.actionSaving.driversLicense = false;
        this.actions.driversLicense.complete = false;
      });
    },
    submitPreferences() {
      this.actionErrors.preferences = '';
      this.actionSaving.preferences = true;
      // 1. Validate Preferences data
      if (!this.actions.preferences.tshirtSize) {
        this.actionErrors.preferences = 'T-Shirt size is required';
        this.actionSaving.preferences = false;
        return;
      }
      if (!this.actions.preferences.dietaryRequirement) {
        this.actionErrors.preferences = 'Dietary requirement is required';
        this.actionSaving.preferences = false;
        return;
      }
      if (this.actions.preferences.dietaryRequirement === 'Other' && !this.actions.preferences.dietaryOther) {
        this.actionErrors.preferences = 'Please specify other dietary requirement';
        this.actionSaving.preferences = false;
        return;
      }
      // 2. Save booking
      this.actions.preferences.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.preferences = false;
      }).catch((error) => {
        this.actionErrors.preferences = 'Failed to save booking';
        this.actionSaving.preferences = false;
        this.actions.preferences.complete = false;
      });
    },
    submitMedicalInfo() {
      this.actionErrors.medical = '';
      this.actionSaving.medical = true;
      // 1. Validate Medical data
      if (this.actions.medical.hasAllergies === null) {
        this.actionErrors.medical = 'Please specify if you have any allergies';
        this.actionSaving.medical = false;
        return;
      }
      if (this.actions.medical.hasAllergies && !this.actions.medical.allergies) {
        this.actionErrors.medical = 'Please specify your allergies';
        this.actionSaving.medical = false;
        return;
      }
      if (this.actions.medical.hasMedicalNeeds === null) {
        this.actionErrors.medical = 'Please specify if you have any medical needs';
        this.actionSaving.medical = false;
        return;
      }
      if (this.actions.medical.hasMedicalNeeds && !this.actions.medical.medicalNeeds) {
        this.actionErrors.medical = 'Please specify your medical needs';
        this.actionSaving.medical = false;
        return;
      }
      // 2. Save booking
      this.actions.medical.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.medical = false;
      }).catch((error) => {
        this.actionErrors.medical = 'Failed to save booking';
        this.actionSaving.medical = false;
        this.actions.medical.complete = false;
      });
    },
    submitEmergencyContactInfo() {
      this.actionErrors.emergencyContact = '';
      this.actionSaving.emergencyContact = true;
      // 1. Validate Emergency Contact data
      if (!this.actions.emergencyContact.name) {
        this.actionErrors.emergencyContact = 'Full Name is required';
        this.actionSaving.emergencyContact = false;
        return;
      }
      if (!this.actions.emergencyContact.phone) {
        this.actionErrors.emergencyContact = 'Contact Number is required';
        this.actionSaving.emergencyContact = false;
        return;
      }
      if (!this.actions.emergencyContact.email) {
        this.actionErrors.emergencyContact = 'Email Address is required';
        this.actionSaving.emergencyContact = false;
        return;
      }
      // 2. Save booking
      this.actions.emergencyContact.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.emergencyContact = false;
      }).catch((error) => {
        this.actionErrors.emergencyContact = 'Failed to save booking';
        this.actionSaving.emergencyContact = false;
        this.actions.emergencyContact.complete = false;
      });
    },
    submitWaiver() {
      this.actionErrors.waiver = '';
      this.actionSaving.waiver = true;
      // 1. Finalize Signature
      const canvas = this.$refs.signatureCanvas;
      this.actions.waiver.signature.image = canvas.toDataURL("image/png");
      // 2. Validate Acknowledgements
      if (!this.actions.waiver.acknowledgement.safety) {
        this.actionErrors.waiver = 'Please acknowledge the Safety section';
        this.actionSaving.waiver = false;
        return;
      }
      if (!this.actions.waiver.acknowledgement.risks) {
        this.actionErrors.waiver = 'Please acknowledge the Risks section';
        this.actionSaving.waiver = false;
        return;
      }
      if (!this.actions.waiver.acknowledgement.indemnity) {
        this.actionErrors.waiver = 'Please acknowledge the Indemnity section';
        this.actionSaving.waiver = false;
        return;
      }
      if (!this.actions.waiver.acknowledgement.release) {
        this.actionErrors.waiver = 'Please acknowledge the Photo/Video Release section';
        this.actionSaving.waiver = false;
        return;
      }
      // 3. Validate Signatory
      if (!this.actions.waiver.signature.name) {
        this.actionErrors.waiver = 'Full Name is required';
        this.actionSaving.waiver = false;
        return;
      }
      // 4. Validate Signature
      if (!this.actions.waiver.signature.image) {
        this.actionErrors.waiver = 'Signature is required';
        this.actionSaving.waiver = false;
        return;
      }
      // Compare signature to empty canvas
      if (this.isCanvasBlank(canvas)) {
        this.actionErrors.waiver = 'Signature is required.';
        this.actionSaving.waiver = false;
        return;
      }
      // 5. Date signature (date + time)
      this.actions.waiver.signature.date = DateTime.now().toISO();
      // 6. Email waiver to guest
      let emailSchedulerEndpoint = this.production() ?
        'https://northamerica-northeast1-renedian-production.cloudfunctions.net/emailScheduler' :
        'https://northamerica-northeast1-renedian-development.cloudfunctions.net/emailScheduler';
      let fields = {
        'full_name': this.actions.waiver.signature.name,
        'date': DateTime.fromISO(this.actions.waiver.signature.date).toFormat('MMMM d, yyyy'),
        'signature_image': this.actions.waiver.signature.image,
      };
      axios.post(emailSchedulerEndpoint, {
        to: this.user.profile.email,
        bcc: ['webmaster@renedian.com','info@renedian.com','piet@renedian.com'],
        from: 'info@renedian.com',
        template: 'waiver-confirmation',
        fields: fields,
        schedule: 'now',
        status: 'pending',
      }).then((response) => {
        // this.emails.confirmation = true;
        console.log('Confirmation email: OK');
      }).catch((error) => {
        // this.emails.confirmation = false;
        console.log('Confirmation email: FAIL', error);
      });
      // 7. Save booking
      this.actions.waiver.complete = true;
      this.saveBooking().then(() => {
        this.snackbar = true; // show snack bar
        this.actionSaving.waiver = false;
      }).catch((error) => {
        console.log('Booking save error: ', error)
        this.actionErrors.waiver = 'Failed to save booking';
        this.actionSaving.waiver = false;
        this.actions.waiver.complete = false;
      });
    },
    async exportToPdf() {
      // Validate the form
      // if (!this.canSubmit) {
      //   alert('Please complete all acknowledgments, enter your name, and provide a signature before submitting.');
      //   return;
      // }

      // Save the signature data if not already saved
      // if (this.$refs.signaturePad && !this.waiver.signatory.signatureData) {
      //   this.actions.waiver.signatory.signatureData = this.$refs.signaturePad.saveSignature().data;
      // }

      // // Wait for DOM to update
      // await this.$nextTick();

      // const element = this.$refs.waiverContent;

      // // Configure html2pdf options
      // const opt = {
      //   margin: 10, // in mm
      //   filename: 'waiver.pdf',
      //   image: { type: 'jpeg', quality: 0.98 },
      //   html2canvas: {
      //     scale: 2,
      //     useCORS: true,
      //     allowTaint: false,
      //     scrollX: 0,
      //     scrollY: -window.scrollY,
      //   },
      //   jsPDF: {
      //     unit: 'mm',
      //     format: 'a4',
      //     orientation: 'portrait',
      //   },
      //   pagebreak: { mode: ['css', 'legacy'] },
      // };

      // // Generate and save the PDF
      // html2pdf().set(opt).from(element).save();
    },
    isCanvasBlank(canvas) {
      const blankCanvas = document.createElement('canvas');
      blankCanvas.width = canvas.width;
      blankCanvas.height = canvas.height;
      return canvas.toDataURL() === blankCanvas.toDataURL();
    },
    getCoordinates(event) {
      const canvas = this.$refs.signatureCanvas;
      const rect = canvas.getBoundingClientRect();
      if (event.touches) {
        const touch = event.touches[0];
        return {
          x: touch.clientX - rect.left,
          y: touch.clientY - rect.top,
        };
      } else {
        return {
          x: event.clientX - rect.left,
          y: event.clientY - rect.top,
        };
      }
    },
    startDrawing(event) {
      this.isDrawing = true;
      const { x, y } = this.getCoordinates(event);
      this.points.push({ x, y, time: Date.now() }); // Save the starting point
    },
    draw(event) {
      if (!this.isDrawing) return;

      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext("2d");
      const { x, y } = this.getCoordinates(event);
      const time = Date.now();

      // Add the new point
      this.points.push({ x, y, time });

      // Draw using quadratic Bézier curve
      if (this.points.length >= 3) {
        const last = this.points[this.points.length - 1];
        const mid = this.points[this.points.length - 2];
        const prev = this.points[this.points.length - 3];

        // Calculate line width based on speed
        const speed = Math.sqrt(
          Math.pow(last.x - mid.x, 2) + Math.pow(last.y - mid.y, 2)
        ) / (last.time - mid.time || 1);
        const lineWidth = Math.max(2, Math.min(10, 10 / (speed + 1)));

        ctx.beginPath();
        ctx.moveTo(prev.x, prev.y);
        ctx.quadraticCurveTo(mid.x, mid.y, last.x, last.y);
        ctx.lineWidth = lineWidth;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.strokeStyle = "#000"; // Black ink
        ctx.stroke();
      }

      // Prevent scrolling on mobile
      if (event.touches) {
        event.preventDefault();
      }
    },
    stopDrawing() {
      this.isDrawing = false;
      this.points = []; // Clear the points after completing the stroke
    },
    clearCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.clearSignature();
    },
    clearSignature() {
      this.actions.waiver.signature.image = null;
    },
    // resizeCanvas() {
    //   const canvas = this.$refs.signatureCanvas;
    //   if (!canvas) return;
    //   canvas.width = 500;
    //   canvas.height = 150;
    //   const ctx = canvas.getContext("2d");
    //   ctx.width = 500;
    //   ctx.height = 150;
    //   ctx.lineCap = "round";
    //   ctx.lineJoin = "round";
    // },
  },
  created() {
    this.initialize(1);
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.resizeCanvas();
  //     window.addEventListener('resize', this.resizeCanvas);
  //   });
  // },
  watch: {
    $route(route) {
      this.initialize(2);
    },
    bookings() {
      this.initialize(3);
    },
  },
  computed: {
    actions() {
      return this.booking.guests[this.guestIndex].actions;
    },
    countCompletedActions() {
      let count = 0;
      if (this.actions.passport.complete) count++
      if (this.actions.driversLicense.complete) count++
      if (this.actions.preferences.complete) count++
      if (this.actions.medical.complete) count++
      if (this.actions.emergencyContact.complete) count++
      if (this.actions.waiver.complete) count++
      return count;
    },
    countTotalActions() {
      return this.booking.guests[this.guestIndex].preferences.riding == 'bike' ? 6 : 5;
    },
    loaded() {
      return !this.loading && this.tour && this.booking && this.guestIndex !== null;
    },
    outstandingActions() {
      return Object.keys(this.booking.actions).filter(key => {
        const action = this.booking.actions[key];
        if (typeof action === 'object') {
          return Object.values(action).some(value => !value);
        }
        return !action;
      });
    },
    fullDate() {
      return DateTime.now().toLocaleString(DateTime.DATE_FULL);
    },
    allAcknowledged() {
      return Object.values(this.actions.waiver.acknowledgement).every(Boolean);
    },
    canSubmit() {
      return this.allAcknowledged && this.actions.waiver.signature.name && this.actions.waiver.signature.image;
    },
  },
  filters: {
    humanDate(value) {
      return DateTime.fromISO(value).toFormat('MMMM d, yyyy');
    },
    fullHumanDate(value) {
      return DateTime.fromISO(value).toFormat('cccc, LLLL d, yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
  .rounded-image {
    border-radius: 10px 10px 0 0;
  }
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  .completed-panel {
    border: 1px solid #4caf50;
  }
  .badge-top {
    background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Fworld-map-160h-10o.png?alt=media&token=c2954802-434c-46b4-b56c-192b3338e849');
    background-size: cover;
    height: 100px;
    .badge-header {
      width: 100%;
      background: url('https://firebasestorage.googleapis.com/v0/b/renedian-production.appspot.com/o/images%2Femployee-badge-hole-small.png?alt=media&token=a94173a3-40c8-4516-9c1f-53be87f5c250');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .badge-status {
    line-height: 1rem !important;
  }
  .badge-photo {
    margin-top: -40px;
    border: 2px solid white;
  }
  .roads-and-weather {
    background: #f7f7f7;
    padding: 11px;
    border-radius: 5px;
    margin: 10px 0;
  }
  .completed-action {
    border-bottom: 1px solid #e0e0e0;
  }
  .signaturePadCanvas {
    border: 1px solid #000;
    border-radius: 5px;
  }
  .page-break {
    page-break-before: always;
    break-before: page;
  }
  .waiver-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .waiver-section {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .waiver {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }
  .waiver .waiver-logo {
    width: 200px;
  }
  .waiver .waiver-paragraph {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .waiver .waiver-bold-text {
    font-weight: bold;
  }
  .waiver .waiver-emphasis-text {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    margin: 20px 0;
  }
  .waiver .waiver-section-heading {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 16px;
  }
  .waiver .waiver-section-subheading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .signature-canvas {
    border: 1px solid #000;
    width: 500px;
    height: 150px;
    touch-action: none; /* Disable touch scrolling for drawing */
    border-radius: 5px;
  }
  .signature-image {
    width:500px;
    height:150px; 
    border: 1px dashed #ccc;
    border-radius: 5px;
  }
  .flipbook {
    height: 90vh;
    margin-bottom: 80px;
  }
</style>
