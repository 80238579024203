<template>
  <v-card>
    <v-progress-linear v-if="loading" color="orange" indeterminate></v-progress-linear>
    <v-card-title>
      <v-icon color="orange darken-3" left>mdi-email-fast-outline</v-icon>
      Automated Email
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab" v-if="loading == false">
        <v-tab>Scheduled Emails</v-tab>
        <v-tab>Templates</v-tab>
        <v-tab>Planning</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab" v-if="loading == false">

        <!-- ------------ Scheduled Emails ------------ -->
        <v-tab-item>
          <v-text-field
            v-if="emails.length"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search Emails"
            single-line
            hide-details
            filled
          ></v-text-field>
          <v-data-table
            :headers="[
              { text: 'Created', value: 'createdAt' },
              { text: 'Type', value: 'template' },
              { text: 'Email To', value: 'to' },
              { text: 'Schedule', value: 'schedule' },
              { text: 'Trigger', value: 'trigger' },
              { text: 'Status', value: 'status' },
              { text: 'Actions', value: 'actions' },
            ]"
            :items="filteredEmails"
            item-key="id"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | formatDateFull }}
            </template>
            <template v-slot:item.trigger="{ item }">
              {{ item.trigger | formatUnixTime }}
            </template>
            <template v-slot:item.template="{ item }">
              {{ item.template | formatTemplateName }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon @click="viewEmail(item)"><v-icon>mdi-magnify</v-icon></v-btn>
              <!-- <v-btn v-if="item.status == 'cancelled'" icon @click="cancelEmail(item)"><v-icon>mdi-check</v-icon></v-btn> -->
              <!-- <v-btn v-else icon @click="enableEmail(item)"><v-icon>mdi-close</v-icon></v-btn> -->
              <v-btn icon @click="deleteEmail(item)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- ------------ Templates ------------ -->
        <v-tab-item>
          <div class="d-flex flex-row">
            <v-list rounded>
              <v-list-item-group v-model="selectedTemplateIndex" color="primary">
                <!-- <v-list-item v-for="template in templates" :key="template.id" v-if="template.id"> -->
                <v-list-item v-for="(template, index) in templates" :key="index">
                  <v-list-item-icon>
                    <v-icon v-if="template.type == 'email'">mdi-email-outline</v-icon>
                    <v-icon v-else>mdi-file-document-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="template.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-list-item-group>
                <v-list-item @click="createTemplate()">
                  <v-list-item-icon>
                    <v-icon>mdi-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>New Template</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-card-text v-if="selectedTemplateIndex >= 0">
              <v-card v-if="editing == false">
                <v-card-title>
                  <div v-if="selectedTemplate == 'email'">{{ selectedTemplate?.subject }}</div>
                  <div v-else>{{ selectedTemplate?.name }}</div>
                  <v-spacer></v-spacer>
                  <v-btn icon v-if="selectedTemplate.type == 'email'" @click="sendTemplate()" :disabled="sending" :loading="sending"><v-icon>mdi-email-outline</v-icon></v-btn>
                  <v-btn icon v-if="previewing" @click="previewing = false"><v-icon>mdi-eye</v-icon></v-btn>
                  <v-btn icon v-else @click="previewing = true"><v-icon>mdi-eye-off</v-icon></v-btn>
                  <v-btn icon @click="variablesDialog = true"><v-icon>mdi-code-tags</v-icon></v-btn>
                  <v-btn icon @click="downloadPDF()" :disabled="generating" :loading="generating"><v-icon>mdi-download</v-icon></v-btn>
                  <v-btn icon @click="editing = true"><v-icon>mdi-pencil</v-icon></v-btn>
                </v-card-title>
                <v-alert v-if="selectedTemplate?.timing && selectedTemplate == 'email'" type="info" tile class="mb-0">
                  <strong>Timing:</strong> {{ selectedTemplate?.timing }}
                </v-alert>
                <v-divider v-else></v-divider>
                <v-sheet v-if="previewing" v-html="handlebarsHtml" style=""></v-sheet>
                <v-sheet v-else v-html="selectedTemplate?.content?.html" style=""></v-sheet>
                <!-- <v-divider></v-divider>
                <v-card-text>
                  <div v-for="(field, index) in selectedTemplate.fields" :key="'field' + index">
                    <div>{{ field }}: {{ selectedTemplate.placeholders[field] }}</div>
                  </div>
                </v-card-text> -->
              </v-card>
              <v-card v-else>
                <v-card-title>
                  <v-text-field v-if="selectedTemplate.type == 'email'" v-model="selectedTemplate.subject" label="Subject" hide-details></v-text-field>
                  <v-text-field v-if="selectedTemplate.type == 'document'" v-model="selectedTemplate.name" label="Name" hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn icon v-if="selectedTemplate.id" @click="saveTemplate()" :disabled="saving" :loading="saving"><v-icon>mdi-content-save</v-icon></v-btn>
                  <v-btn icon v-else @click="saveTemplate()" :disabled="saving" :loading="saving"><v-icon>mdi-plus</v-icon></v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <!-- <v-text-field v-model="selectedTemplate.slug" label="Slug" :disabled="selectedTemplate.id && selectedTemplate.slug != 'unique-identifier' ? true : false"></v-text-field> -->
                  <v-text-field v-model="selectedTemplate.slug" label="Slug" :disabled="selectedTemplate.id != null"></v-text-field>
                  <v-text-field v-if="selectedTemplate.type == 'email'" v-model="selectedTemplate.name" label="Name"></v-text-field>
                  <v-select v-model="selectedTemplate.type" :items="types" label="Type"></v-select>
                  <v-text-field v-if="selectedTemplate.type == 'email'" v-model="selectedTemplate.timing" label="Timing"></v-text-field>
                  <v-select v-if="selectedTemplate.type == 'email'" v-model="selectedTemplate.attachment" :items="possibleAttachments" label="Attachment" item-text="name" item-value="slug"></v-select>
                  <v-textarea v-model="selectedTemplate.content.html" background-color="grey lighten-4" auto-grow outlined hide-details></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <div v-for="(field, index) in selectedTemplate.fields" :key="'field' + index">
                    <v-text-field v-model="selectedTemplate.placeholders[field]" :label="field"></v-text-field>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </div>
        </v-tab-item>

        <!-- ------------ Planning ------------ -->
        <v-tab-item>
          <v-alert type="success">
            <strong>A Note from Rene</strong>
            <ul>
              <li>Timing: 10 minutes after booking</li>
              <li>Trigger: Booking record created</li>
              <li>Content details: Thank you note from Rene</li>
              <li>Email to come from: rene@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="success">
            <strong>Booking Confirmation</strong>
            <ul>
              <li>Timing:  Immediately upon booking and receipt of deposit</li>
              <li>Trigger: Booking record created</li>
              <li>
                Content details: invoice showing which trip (name and dates), 
                motorcycle selection, any motorcycle details (I.e. Low seat, top case, tank bag)
                rooming selection (solo or shared) reiterate cancellation policy and link to page with all details on cxl and terms and conditions.
                Invoice should also show deposit paid, the currency of transaction, when the balance is due (and what the amount currently is).
                There should also be mention of what to expect next in terms of our communication to them.
                This email also needs to include link to the rider portal (email address as user name and temp password).
              </li>
              <li>Email to come from: info@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="success">
            <strong>Booking Receipt</strong>
            <ul>
              <li>Timing:  Immediately upon booking and receipt of deposit</li>
              <li>Trigger: Booking record created</li>
              <li>
                Content details: ...
              </li>
              <li>Email to come from: info@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="success">
            <strong>Portal Login Link</strong>
            <ul>
              <li>Timing:  Immediately upon booking and receipt of deposit</li>
              <li>Trigger: Booking record created</li>
              <li>
                Content details: ...
              </li>
              <li>Email to come from: info@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Monthly Check-in</strong>
            <ul>
              <li>Timing:  Every month from date of booking until 60 days before trip starts.</li>
              <li>
                Content details: 
                Fun, quick stories about where they are travelling to [topics to include animals, history, mountain passes, etc] 
                Reminder at the bottom of the email that provides link to rider Portal that they can use to upload insurance details, flights details, any pre or post trip needs, etc, that have not been completed yet (We recognize that this information will be available in the 2-12 months before the trip runs, so need to provide regular opportunities for riders to upload their details when convenient for them)
              </li>
              <li>Email to come from: info@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>75 Day reminder</strong>
            <ul>
              <li>Timing:  75 days before trip starts</li>
              <li>
                Content details: 
                A friendly reminder that the final invoice is due in 2 weeks. 
                Link to invoice, and review of payment options. 
              </li>
              <li>Email to come from: info@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>60 Day Final Invoice</strong>
            <ul>
              <li>Timing:  61 days before trip starts</li>
              <li>
                Content details: 
                A friendly reminder that the final invoice is due. 
                Link to invoice, and review of payment options.
                Review cancellation policy
                Review cancellation fees due
              </li>
              <li>Email to come from: info@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Meet your Crew!</strong>
            <ul>
              <li>Timing: 45 days before tour starts</li>
              <li>
                Content details: 
                An electronic introduction from the guide and driver on that tour, as well as Danise in the office joint email from guide and driver
                encouraging pax to contact them directly if any questions arise. Or they are welcome to continue to contact the office. 
              </li>
              <li>Email to come from: info@renedian.com (cc guide/driver/Danise)</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>30 Day: Packing List</strong>
            <ul>
              <li>Timing:  30 days before trip starts</li>
              <li>
                Content details: 
                Reminder with a link to the packing list in Rider Portal
                and final items to be done (visit to travel doctor)
              </li>
              <li>Email to come from: info@renedian.com, cc in guide and driver.</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>14 Day: Arrival Details & Let’s check in with a phone call!</strong>
            <ul>
              <li>Timing:  14 days before tour</li>
              <li>
                Content details: 
                Confirming Flight details - Aaron - can this be extracted from the Rider Portal Database?
                Further, how can we automate details such as pre/post hotels, airport transfer instructions - would there be wisdom in creating a field for this in the rider portal? Danise can complete these details if we do the booking, otherwise they will have to do it themselves. 
                Detailed schedule for the first 48 hours of their trip.
                Offer riders the opportunity to get on the phone with either their guide, Danise, or someone from the Renedian office to go over any last minute details.  Let them know this will be the last scheduled communication before the trip starts, although they are welcome to contact us via phone, email, to the guide or driver or Danise at any time if they wish.
              </li>
              <li>Email to come from: Danise@renedian.com, cc in guide and driver.</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Notice on Guide Tipping</strong>
            <ul>
              <li>Timing: Five days before the last tour date</li>
              <li>
                Content details: 
                Re-iteration of suggested tipping amounts for exceptional service
                Details on how to pay tip via credit card (link) 
                Upon payment of tip, automated email will be sent with confirmation tip amount
                Note to guide field for the rider to leave a comment that will go out in email. 
              </li>
              <li>Email to come from: danise@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Departure Details</strong>
            <ul>
              <li>Timing: Two days before the end of the tour</li>
              <li>
                Content details: 
                Details confirming any extra end of tour hotels
                flight details, and airport transfer times and details. 
              </li>
              <li>Email to come from: danise@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Wrap up</strong>
            <ul>
              <li>Timing: Last day of  tour (fly out day).</li>
              <li>
                Content details: 
                A thank you for participation/riding with us
                Reminder of the Alumni program (details) 
                Request for review on Google, Trip Advisor, etc. 
                A not that Liz will contact them to request a final survey 
              </li>
              <li>Email to come from: guide and driver @ renedian.com + cc: info@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>A final note from Rene</strong>
            <ul>
              <li>Timing: Tour end + 7 days</li>
              <li>
                Content details: 
                Mimic the pre-trip note in style and form, a word of thanks from me, and also a sentence saying that although you have likely shared feedback with Liz on her asked for survey, if there are any feedback from the trip that they would like to pass along to me in confidence, I would greatly appreciate them sending it directly to my email or phoning me on my direct line.
              </li>
              <li>Email to come from: rene@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>1 Year Anniversary of Tour start date</strong>
            <ul>
              <li>Timing: Tour start date + 1yr</li>
              <li>
                Nice note about starting a year ago (TBC)
                Reminder of Alumni Program
                Reminder to check out www.advmotorcycleparts.com
                Reminder to check out www.canadianmotorcyclerentals.com
                Reminder to check out Facebook Alumni Page
                Reminder that we do private tours
                Ask for referrals
              </li>
              <li>Email to come from: rene@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Complimentary Jacket</strong>
            <ul>
              <li>Timing: After 3 tours</li>
              <li>
                Send an email to Rene? to arrange shipment of jacket
              </li>
              <li>Email to come from: rene@renedian.com</li>
            </ul>
          </v-alert>
          <v-alert type="info">
            <strong>Tour Emails (later phase)</strong>
            <ul>
              <li>Timing: Daily (on tour)</li>
              <li>Daily itinerary</li>
              <li>Weather</li>
              <li>Activities?</li>
              <li>Email to come from: guide?</li>
            </ul>
          </v-alert>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-dialog v-model="variablesDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Data Object
          <v-spacer></v-spacer>
          <v-btn icon @click="copyToClipboard()" :loading="copying"><v-icon>mdi-content-copy</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <div>{</div>
            <div v-for="(variable, index) in templateVariables" :key="'variable' + index" class="ml-4">
              <div>{{ variable }}</div>
            </div>
          <div>}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="variablesDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { getFirestore, onSnapshot, doc, setDoc, collection, deleteDoc } from 'firebase/firestore'
import Handlebars from 'handlebars';
import { DateTime } from "luxon"
import axios from 'axios'

// Register a simple currency helper
Handlebars.registerHelper('currency', function (amount) {
  const num = parseFloat(amount);
  const formatted = Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${formatted} (CAD)`;
});
Handlebars.registerHelper('money', function (amount) {
  const num = parseFloat(amount);
  const formatted = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${formatted}`;
});
Handlebars.registerHelper('eq', function (a, b) {
  return a === b;
});
Handlebars.registerHelper('multiply', function (a, b) {
  return a * b;
});
Handlebars.registerHelper('default', function (a, b) {
  return a || b;
});
Handlebars.registerHelper('gt', function (a, b) {
  return a > b;
});

export default {
  name: 'AdminAutomationEmail',
  data: () => ({
    search: '',
    loading: false,
    editing: false,
    saving: false,
    sending: false,
    generating: false,
    copying: false,
    previewing: true,
    variablesDialog: false,
    tab: 0,
    selectedTemplateIndex: 0,
    templates: [],
    emails: [],
    templateVariables: [],
    types: [
      'email',
      'document',
    ],
    timing: [
      { text: 'Instant', value: 'instant' },
      { text: 'Scheduled', value: 'scheduled' },
    ],
  }),
  created() {
    this.loading = true
    this.initialize()
  },
  methods: {
    initialize() {
      const db = getFirestore()
      const collectionRef = collection(db, 'emailTemplates')
      onSnapshot(collectionRef, (snapshot) => {
        this.loading = true
        let items = []
        snapshot.forEach((doc) => {
          let item = doc.data()
          item.id = doc.id
          items.push(item)
        })
        console.log('delta')
        this.templates = items
        this.loading = false
      })
      // Get emails
      onSnapshot(collection(db, 'emails'), (snapshot) => {
        console.log('get emails')
        this.loadingEmails = true
        let items = []
        snapshot.forEach((doc) => {
          let item = doc.data()
          item.id = doc.id
          items.push(item)
        })
        this.emails = items
        this.loadingEmails = false
      })
    },
    createTemplate() {
      const newTemplate = {
        id: null,
        name: 'New Template',
        subject: 'New Subject',
        slug: '',
        timing: 'instant',
        type: 'email',
        fields: [],
        placeholders: {},
        attachment: null,
        content: {
          html: '<html>New Content</html>',
          text: 'New Content',
        },
      };
      this.templates.push(newTemplate);
      this.selectedTemplateIndex = this.templates.length - 1;
      this.editing = true;
    },
    sendTemplate() {
      this.sending = true
    },
    saveTemplate() {
      this.saving = true;
      const db = getFirestore();
      const collectionRef = collection(db, 'emailTemplates');

      if (this.selectedTemplate.id) {
        // Update existing document logic
        this.selectedTemplate.updated = new Date();
        this.selectedTemplate.fields = this.templateVariables;

        // Ensure placeholders is defined
        this.selectedTemplate.placeholders = this.selectedTemplate.placeholders || {};

        // Merge placeholders
        this.selectedTemplate.placeholders = this.templateVariables.reduce((acc, variable) => {
          acc[variable] = this.selectedTemplate.placeholders.hasOwnProperty(variable)
            ? this.selectedTemplate.placeholders[variable]
            : "placeholder";
          return acc;
        }, {});

        // Ensure attachment is defined
        this.selectedTemplate.attachment = this.selectedTemplate.attachment || null;

        // Add all of the attachment fields to the fields array
        if (this.selectedTemplate.attachment) {
          // Find the attachment template
          const attachmentTemplate = this.templates.find(
            (template) => template.slug === this.selectedTemplate.attachment
          );
          console.log("found attachment", attachmentTemplate);
          // Add all of the attachment fields to the fields array
          if (attachmentTemplate) {
            this.selectedTemplate.fields = [
              ...new Set([...this.selectedTemplate.fields, ...attachmentTemplate.fields]),
            ];
          }
        }

        // Update the document in Firestore
        setDoc(doc(collectionRef, this.selectedTemplate.id), this.selectedTemplate)
          .then(() => {
            console.log('Template updated successfully');
            this.saving = false;
            this.editing = false;
          })
          .catch((error) => {
            console.error('Error updating document: ', error);
            this.saving = false;
          });
      } else {
        // Create new document logic
        const slug = this.selectedTemplate.slug;
        if (!slug) {
          this.saving = false;
          alert('Slug is required');
          return;
        }

        // Check for uniqueness
        const existingTemplate = this.templates.find(
          (template) => template.slug === slug && template.id
        );
        if (existingTemplate) {
          this.saving = false;
          alert('A template with this slug already exists');
          return;
        }

        // Set the id of the template to match the slug
        this.selectedTemplate.id = slug;
        this.selectedTemplate.updated = new Date();
        this.selectedTemplate.fields = this.templateVariables;

        // Ensure placeholders is defined
        this.selectedTemplate.placeholders = this.selectedTemplate.placeholders || {};

        // Merge placeholders
        this.selectedTemplate.placeholders = this.templateVariables.reduce((acc, variable) => {
          acc[variable] = this.selectedTemplate.placeholders.hasOwnProperty(variable)
            ? this.selectedTemplate.placeholders[variable]
            : "placeholder";
          return acc;
        }, {});

        // Ensure attachment is defined
        this.selectedTemplate.attachment = this.selectedTemplate.attachment || null;

        // Add all of the attachment fields to the fields array
        if (this.selectedTemplate.attachment) {
          // Find the attachment template
          const attachmentTemplate = this.templates.find(
            (template) => template.slug === this.selectedTemplate.attachment
          );
          console.log("found attachment", attachmentTemplate);
          // Add all of the attachment fields to the fields array
          if (attachmentTemplate) {
            this.selectedTemplate.fields = [
              ...new Set([...this.selectedTemplate.fields, ...attachmentTemplate.fields]),
            ];
          }
        }

        // Create a new document in Firestore with the id matching the slug
        setDoc(doc(collectionRef, slug), this.selectedTemplate)
          .then(() => {
            console.log('Template created successfully');
            this.saving = false;
            this.editing = false;
          })
          .catch((error) => {
            console.error('Error creating document: ', error);
            this.saving = false;
          });
      }
    },
    downloadPDF() {
      console.log(this.selectedTemplate)
      this.generating = true
      const endpoint = this.production()
          ? 'https://northamerica-northeast1-renedian-production.cloudfunctions.net/generatePdf' 
          : 'https://northamerica-northeast1-renedian-development.cloudfunctions.net/generatePdf';
      axios.post(
        endpoint, 
        {
          html: this.previewing ? this.handlebarsHtml : this.selectedTemplate.content.html,
          template: '',
          data: {},
        }, 
        {
          responseType: 'blob',
        }
      ).then((response) => {
        console.log('PDF generated successfully');
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.selectedTemplate.slug + '.pdf');
        document.body.appendChild(link);
        link.click();
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        this.generating = false
      }).catch((error) => {
        console.error('Error downloading PDF:', error);
        this.generating = false
      });
    },
    async copyToClipboard() {
      this.copying = true
      try {
        const text = `{\n${this.templateVariables.join(': "",\n')}: ""\n}`;
        await navigator.clipboard.writeText(text);
        console.log('Copied to clipboard');
        this.copying = false
      } catch (err) {
        console.error('Failed to copy: ', err);
        this.copying = false
      }
    },
    viewEmail(email) {
      console.log(email)
    },
    // cancelEmail(email) {
    //   console.log('cancel', email)
    //   // Delete the scheduled email
    //   const db = getFirestore()
    //   const docRef = doc(db, 'emails', email.id)
    //   setDoc(docRef, { status: 'cancelled' }, { merge: true }).then(() => {
    //     console.log('cancelled')
    //   }).catch((error) => {
    //     console.error('Error updating document: ', error)
    //   })
    //   // Update the emails
    //   this.emails = this.emails.map(item => {
    //     if (item.id === email.id) {
    //       item.status = 'cancelled'
    //     }
    //     return item
    //   })
    // },
    // enableEmail(email) {
    //   // Delete the scheduled email
    //   const db = getFirestore()
    //   const docRef = doc(db, 'emails', email.id)
    //   setDoc(docRef, { status: 'pending' }, { merge: true }).then(() => {
    //     console.log('pending')
    //   }).catch((error) => {
    //     console.error('Error updating document: ', error)
    //   })
    //   // Update the emails
    //   this.emails = this.emails.map(item => {
    //     if (item.id === email.id) {
    //       item.status = 'pending'
    //     }
    //     return item
    //   })
    // }
    deleteEmail(email) {
      const db = getFirestore()
      const docRef = doc(db, 'emails', email.id)
      deleteDoc(docRef).then(() => {
        console.log('deleted')
      }).catch((error) => {
        console.error('Error deleting document: ', error)
      })
    }
  },
  computed: {
    selectedTemplate() {
      return this.templates[this.selectedTemplateIndex]
    },
    handlebarsHtml() {
      // Get the template string
      let templateString = this.selectedTemplate.content.html;

      // Compile the template
      const template = Handlebars.compile(templateString);

      // Prepare the data for rendering
      const data = {
        ...this.selectedTemplate.placeholders,
        charges: [
          {
            name: "Section Header",
            type: "Tour",
            items: [
              {
                amount: 34995,
                name: "Motorcycle Rider",
                quantity: 1,
                type: "basic",
              },
              {
                amount: 34995,
                name: "Room",
                quantity: 1,
                type: "basic",
              },
            ],
          },
        ].flatMap(section =>
          section.items.map(item => ({
            section: section.name, // Flatten section
            amount: item.amount,
            name: item.name,
            quantity: 1,
          }))
        ),
      };

      // Render the template with the data
      const html = template(data);

      return html;
    },
    possibleAttachments() {
      // Filter for templates that are not currently selected and that are of type document
      let templates = this.templates.filter(template => template.id !== this.selectedTemplate.id && template.type === 'document')
      templates = templates.map(template => {
        return {
          name: template.name,
          slug: template.slug,
        }
      })
      // Add "none" as the first option
      templates.unshift({
        name: '<None>',
        slug: null,
      })
      return templates
    },
    filteredEmails() {
      if (!this.search) {
        return this.emails
      }
      const searchLower = this.search.toLowerCase()
      return this.emails.filter(email => {
        const to = email.to.toLowerCase()
        const template = email.template.toLowerCase()
        const emailStatus = email.status.toLowerCase()

        return (
          to.includes(searchLower) ||
          template.includes(searchLower) ||
          emailStatus.includes(searchLower)
        )
      })
    }
  },
  watch: {
    selectedTemplate() {
      if (!this.selectedTemplate) return
      // Get variables from content
      let variables = []
      let content = this.selectedTemplate.content.html
      let regex = /{{(.*?)}}/g
      let match
      while (match = regex.exec(content)) {
        variables.push(match[1])
      }
      // Remove duplicates
      variables = [...new Set(variables)]
      // Remove reserved handlebars commands like if, else, /if, each, etc.
      variables = variables.filter(variable => !variable.includes('#') && !variable.includes('/') && variable != 'else')
      // Add all of the attachment fields to the fields array
      if (this.selectedTemplate.attachment) {
        // Find the attachment template
        const attachmentTemplate = this.templates.find(template => template.slug === this.selectedTemplate.attachment)
        const attachmentFields = attachmentTemplate.fields
        // Add all of the attachment fields to the fields array
        variables = [...new Set([...variables, ...attachmentFields])]
      }
      // Set template variables
      this.templateVariables = variables
    },
    $router(to, from) {
      this.initialize()
    }
  },
  filters: {
    formatTemplateName(value) {
      // Capitalize the first letter and replace dashes with spaces
      return value.charAt(0).toUpperCase() + value.slice(1).replace(/-/g, ' ')
    },
    formatDateFull(value) {
      // Convert from Firebase Timestamp (ie. {seconds: 1630000000, nanoseconds: 0}) to November 10, 2024 format
      return DateTime.fromSeconds(value.seconds).toFormat('MMMM d, yyyy')
    },
    formatUnixTime(value) {
      return DateTime.fromSeconds(value).toFormat('MMM d, yyyy h:mm a')
    },
  }
}
</script>
